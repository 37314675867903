import Home from "./views/Home"
import React, { useState } from 'react';
import GeneralState from "./stores/general/general.state"
import Unauthorized from "./views/Unauthorized"
import Config from "./config"
import TagManager from "react-gtm-module"

const tagManagerArgs = {
  gtmId: "GTM-5HW3S27"
  // gtmId: "GTM-5HW3S"
}
console.log("V 1.0.5")
TagManager.initialize(tagManagerArgs)
function App() {
  // const [hasCookieBot, setHasCookieBot] = useState(undefined);
  // eslint-disable-next-line
  return (
    <>
      <div className="h-screen w-full">
        <GeneralState>  <Home /></GeneralState>
        <Unauthorized />
      </div>
    </>
  )
}

export default App
