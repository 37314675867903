import uno_antes from "../assets/images/antes/uno_antes.png";
import dos_antes from "../assets/images/antes/dos_antes.png";
import tres_antes from "../assets/images/antes/tres_antes.png";
import cuatro_antes from "../assets/images/antes/cuatro_antes.png";
import cinco_antes from "../assets/images/antes/cinco_antes.png";

import uno_preparacion from "../assets/images/preparacion/uno_preparacion.png";
import dos_preparacion from "../assets/images/preparacion/dos_preparacion.png";
import tres_preparacion from "../assets/images/preparacion/tres_preparacion.png";

export const antecedentes = [
  {
    id: 1,
    title: "Inicio de la enfermedad",
    subsubtitle:
      "Presentó retardo en su desarrollo motor. Logró el sostén cefálico después de los 5 meses de vida.",
    subtitle: "Lis es un paciente pediátrico de 5 años de edad. ",
    imagen: "",
    titleButton: "Continuar",
  },
  {
    id: 2,
    title: "Evolución",
    subtitle:
      "Fue diagnosticada con el síndrome de niño hipotónico por lo que fue  tratada con fisioterapia de rehabilitación. ",
    subsubtitle: "",
    imagen: "",
    titleButton: "Continuar",
  },
  {
    id: 3,
    title: "Estudios diagnósticos",
    subtitle:
      "Se inició con protocolo de estudio con electromiografía y prueba  molecular.",
    subsubtitle:
      "Los resultados mostraron una deleción homocigótica de exones 7 y 8 de SMN1.",
    imagen: "",
    titleButton: "Continuar",
  },
];

export const antesdeaplicacion = [
  {
    id: 1,
    title: "Retirar de refrigeración",
    subtitle: "Retire el vial del refrigerador para",
    subsubtitle: "  que alcance una temperatura de 25°C.[2]",
    imagen: uno_antes,
    titleButton: "Ver paso 2",
  },
  {
    id: 2,
    title: "Inspeccionar la solución",
    subtitle: "Inspeccione visualmente  que la ",
    subsubtitle: " solución esté libre de partículas y sea clara e incolora.[2]",
    imagen: dos_antes,
    titleButton: "Ver paso 3",
  },
  {
    id: 3,
    title: "Preparar la solución",
    subtitle: "Utilice una técnica aséptica para",
    subsubtitle:
      " preparar la solución de Nusinersen para su administración intratecal.[3]",
    imagen: tres_antes,
    titleButton: "Ver paso 4",
  },
  {
    id: 4,
    title: "En caso de no usarse",
    subtitle: "Si el vial permanece cerrado y no ",
    subsubtitle: "se utiliza, se debe regresar al refrigerador (2-8°C).[2]",
    imagen: cuatro_antes,
    titleButton: "Ver paso 5",
  },
  {
    id: 5,
    title: "Llena la jeringa",
    subtitle: "Retire la tapa e inserte la aguja",
    subsubtitle:
      " por el centro del tapón para extraer 5ml de Nusinersen del vial. Tenga listo en un tubo para recolectar 5ml de LCR.[2]",
    imagen: cinco_antes,
    titleButton: "Iniciar la preparación del paciente",
  },
];

export const preparacion = [
  {
    id: 1,
    title: "Posición",
    subtitle:
      "Coloque al paciente en decúbito lateral, de esta manera el espacio intervertebral se abrirá facilitando el paso de la aguja.[3]",
    subsubtitle: "",
    imagen: uno_preparacion,
    titleButton: "Ver asepsia",
  },
  {
    id: 2,
    title: "Asepsia",
    subtitle:
      "Es de suma importancia realizar asepsia y antisepsia en la región anatómica para evitar el riesgo de infecciones.[3]",
    subsubtitle: "",
    imagen: dos_preparacion,
    titleButton: "Ver anestesia",
  },
  {
    id: 3,
    title: "Anestesia",
    subtitle:
      "En algunas circunstancias se puede realizar el procedimiento bajo anestesia, según el criterio médico. Previo al proceso, es recomendable aplicar anestesia local subcutánea.[3]",
    subsubtitle: "",
    imagen: tres_preparacion,
    titleButton: "Continuar",
  },
];
