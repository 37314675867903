import {
  CHANGE_AUDIO_STATE,
  CHANGE_INTERACTION_STATE,
  CHANGE_FULLSCREEN_STATE,
  NEXT_AUDIO,
  RESET_EXPERIENCIA,
  BLOCK_ROUTE,
  SET_ROUTES_BLOCKED_STORE,
  CHANGE_MUTED_STATE  
} from "../../types";

const reducer = (state, action) => {
  switch (action.type) {
    case NEXT_AUDIO:
      if (state.indexAudios + 1 !== state.totalAudios) {
        const nextnumber = state.indexAudios + 1;
        return {
          ...state,
          indexAudios: nextnumber,
          currentSubtitle: state.subtitulos[nextnumber],
          currentAudio: state.audios[nextnumber],
        };
      } else {
        alert("Son todos");
        return {
          ...state,
        };
      }
    case RESET_EXPERIENCIA:
        return {
          ...state,
          indexAudios: 0,
          currentSubtitle: state.subtitulos[0],
          currentAudio: state.audios[0],
        };
    case CHANGE_AUDIO_STATE:
      return {
        ...state,
        isAudioFinish: action.payload,
      };
    case CHANGE_INTERACTION_STATE:
      return {
        ...state,
        isInteractionFinish: action.payload,
      };
    case CHANGE_MUTED_STATE:
      return {
        ...state,
        isMuted:action.payload
      }
    case CHANGE_FULLSCREEN_STATE:

      return{
        ...state,
        isFullScreen:action.payload,
      };
    case SET_ROUTES_BLOCKED_STORE:
      let initial = localStorage.getItem("initalRoutesBlockedState");
      if (initial) {
        return {
          ...state,
          initalRoutesBlockedState: JSON.parse(initial),
        };
      } else {
        return {
          ...state,
        };
      }

    case BLOCK_ROUTE:
      let newBlockedState = {
        ...state.initalRoutesBlockedState,
        [action.payload]: true,
      };

      window.localStorage.setItem(
        "initalRoutesBlockedState",
        JSON.stringify(newBlockedState)
      );
      return {
        ...state,
        initalRoutesBlockedState: newBlockedState,
      };

    default:
      return state;
  }
};
export default reducer;
