import React, { useContext, useEffect } from "react";
import ReactAudioPlayer from 'react-audio-player';
import { Img, Rutas } from "../types";
import { FINISH_A, FINISH_B } from "../types";
import { useHistory } from "react-router-dom";
import { audios } from "../data/dataAudios";
import generalContext from "../stores/general/general.context";

const Finish = ({ type }) => {
  return (
    <>
      {type === FINISH_A && <FinishA />}
      {type === FINISH_B && <FinishB />}

      <div className="z-40 fixed inset-0 h-full degrade_blue   landscape:hidden flex justify-center items-center">
        <div className="w-full fixed inset-0 h-full degrade_blue2">
          <div className="w-full fixed inset-0 h-full  flex flex-col justify-center items-center">
            <div className="landscape:flex w-10/12 h-80 text-greencorp text-lg Gilroy-Regular	  rounded-3xl flex-col justify-between p-5 items-right bg-white">
              <div className="h-2/5 ">
                <p className="text-left ">
                  Recuerde utilizar su teléfono en{" "}
                  <span className="Gilroy-Bold ">modo horizontal </span> en esta
                  sección.
                </p>
              </div>
              <div className="h-1/5 self-center flex justify-center items-center">
                <img src={Img.movilr} alt="gira tu teléfono" />
              </div>
              <div className="h-2/5 self-center text-center flex justify-center  items-center ">
                <p className="Gilroy-Bold">
                  Por favor gire su teléfono para continuar
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CardF = ({ image, alt, messagea, messageb }) => {
  return (
    <div className=" shadow-3xl rounded-xl bg-white opacity-80 mb-5 2xl:p-2 4xl:py-5 4xl:px-3 h-11/12 lg:h-full ">
      <div className="h-3/6 flex justify-center items-end lg:items-center ">
        <img src={image} alt="alt" className=" w-8 3xl:w-12 4xl:w-10" />
      </div>
      <div className="h-3/6 flex flex-col justify-start pt-1  4xl:-mt-1 text-bluecorp ">
        <p className="Gilroy-Regular 2xl:text-xs 3xl:text-base text-center">
          {messagea}
        </p>
        <p className="Gilroy-Regular 2xl:text-xs 3xl:text-base text-center">
          {messageb}
        </p>
      </div>
    </div>
  );
};

const FinishA = () => {
  
  useEffect( () => {
    window.dataLayer.push({
      event: 'initialization',
      biib_asset_code: 'other|mx|biogenlinc',
      biib_name: 'biogenlinc - simulador lis',
      biib_region: 'latam',
      biib_language: 'es',
      biib_country: 'mx',
      biib_env: 'prod',
      spaWebsite: true,
     } );
    window.dataLayer.push({
      event: 'virtualPageview',
      virtualPageURL: '/lis/final',
      virtualPageTitle: 'Lis App | Final Page',
      spaWebsite: true,
      contentType: "medical education",
      format: "application",
      contentID:"undefined",
      therapyArea:"sma",
      is_promotional:'non-promotional',
      is_scientific_content: 'No',
      keywords: "",
      product: 'no product',
      target_audience: 'hcp',
      visitorLoginState: 'anonymous',
      pageCategoryLevel1:"lys|application",
      biogenEmail:"FALSE",
      videoLive:"FALSE"
    });
  }, [])
  const {
    isMuted,
  } = useContext(generalContext);

  let history = useHistory();
  return (
    <>
    <ReactAudioPlayer
 src={audios[5]}
  autoPlay
  muted={isMuted}
  />
    <div className="bg-white h-screen h-full">
      <div className=" animate-fadein w-screen h-screen h-full degrade_blue2  hidden landscape:flex">
        <div className="w-full flex flex-col justify-around 2xl:px-4 25xl:px-6  3xl:px-8 4xl:px-8">
          <div className="flex-1 w-full flex justify-center items-center text-two Gilroy-SemiBold 2xl:text-2xl 4xl:text-4xl	">
            <h2>¡Excelente práctica!</h2>
          </div>
          <div className=" w-full xl:h-167  grid grid-flow-col md:auto-rows-fr xl:auto-cols-fr gap-1 2xl:gap-3 25xl:gap-5 3xl:gap-4 4xl:gap-5 iphone67:gap-5	">
            <CardF
              image={Img.person_finish}
              alt="paciente"
              messagea="Más de 10,000 pacientes"
              messageb="tratados con Nusinersen."
              />
            <CardF
              image={Img.calendar_finish}
              alt="calendario"
              messagea="Programa clínico de más de "
              messageb="7 años de seguimiento."
              />
            <CardF
              image={Img.escudo_finish}
              alt="escudo"
              messagea="Seguridad y eficacia en "
              messageb="bebés, niños y adultos con AME."
              />
          </div>
          <div className="flex w-full pt-4">
            <div className="flex w-2/3 justify-center">
              <div>
              <p className="text-justify text-3xxs text-white">
              1. Fowler MJ et al. Intrathecal drug delivery in the era of nanomedicine. Advanced Drug Delivery Reviews 2020. <br />
2. Spinraza®, información para prescribir. <br />
3. Munive Báez, Leticia. (2014). Punción lumbar: Condiciones e indicaciones en pediatría. Acta pediátrica de México, 35(5), 423-427.
              </p>
              </div>
            </div>
            <div className="flex w-1/3">
            <div
            onClick={() => {
              history.push(Rutas.FINALB);
            }}
            className="flex-1 w-full flex justify-end items-center"
            >
            <p className="text-white text-lg	m-3.5 ">Siguiente</p>
            <div className=" h-16 w-4813  shadow-3xl  bg-white opacity-90	 border-radiuss 4xl:rounded-3xl	flex justify-center items-center">
              <img
                src={Img.rightrow}
                className="animate-pulse"
                alt="flecha derecha"
                />
            </div>
          </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</>
  );
};
const FinishB = () => {
  useEffect( () => {
    window.dataLayer.push({
      event: 'initialization',
      biib_asset_code: 'other|mx|biogenlinc',
      biib_name: 'biogenlinc - simulador lis',
      biib_region: 'latam',
      biib_language: 'es',
      biib_country: 'mx',
      biib_env: 'prod',
      spaWebsite: true,
     } );
    window.dataLayer.push({
      event: 'virtualPageview',
      virtualPageURL: '/lis/despedida',
      virtualPageTitle: 'Lis App | Farewell Page',
      spaWebsite: true,
      contentType: "medical education",
      format: "application",
      contentID:"undefined",
      therapyArea:"sma",
      is_promotional:'non-promotional',
      is_scientific_content: 'No',
      keywords: "",
      product: 'no product',
      target_audience: 'hcp',
      visitorLoginState: 'anonymous',
      pageCategoryLevel1:"lys|application",
      biogenEmail:"FALSE",
      videoLive:"FALSE"
    });
  }, [])
  const openLink = () => {
    window.open("https://www.neurodiem.com.mx/sign-up?utm_medium=3rd-party-other&utm_source=LIS&utm_campaign=ND_MX_LIS_QRcode_2021&ch_group=3rd_party");
  };
  return (
    <>
    <div className="bg-white h-screen h-full">
      <div className=" animate-fadein w-full h-screen h-full degrade_blue2 relative  hidden landscape:flex">
        <div className="absolute right-0 bottom-0  xl:w-6/12 h-full flex justify-end items-end">
          <img
            className=" w-11/12 "
            alt="ilustracion"
            src={Img.ilustracion_finish}
            />
        </div>
        <div className="w-full relative h-full 2xl:px-6 4xl:px-8  ">
          <div className="h-3/6  w-full flex flex-col justify-around xl:pt-8   Gilroy-SemiBold">
            <h2 className="text-left 2xl:text-2xl 4xl:text-3xl 	 pb-2">
              ¡Gracias por participar!
            </h2>
            <h4 className=" 2xl:text-tiny 4xl:text-base Gilroy-Light w-7/12">
              Si desea profundizar en referencias científicas acerca de los
              beneficios de la administración intratecal y otros temas en
              Neurociencias lo invitamos a registrarse en Neurodiem, un servicio
              de Biogen.
            </h4>
          </div>
          <div className="h-3/6 25xl:h-3/6 w-3/6  relative flex flex-col justify-around items-center">
            <button
              onClick={openLink}
              className="outline-none focus:outline-none  bg-whitecorp relative text-bluecorp sm:text-sm self-start lg:text-base text-base shadow-lg font-normal rounded-xl sm:h-10 md:h-10 lg:h-14 xl:h-14 2xl:w-10/12	 4xl:w-11/12"
              >
              Pulse aquí
              <div className="absolute right-0   top-0 bottom-0 flex justify-center items-center">
                <img
                  src={Img.rightrow}
                  className=" 2xl:mr-4 3xl:mr-6 4xl:mr-16 w-2 "
                  alt="flecha"
                  />
              </div>
            </button>
            <div className="flex w-full  justify-around items-center ">
              <img className="w-40 " src={Img.logouno_finish} alt="logo dos" />
              <div>
                <img
                  src={Img.neurodiem}
                  className="w-40 h-auto "
                  alt="logo uno"
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default Finish;
