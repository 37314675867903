import React, { useEffect, useState } from "react";
import { Img } from "../../types";

import {
  ANTES_APLICACION,
  PREPARACION_PACIENTE,
  ANTECEDENTES,
} from "../../types";

const Card = ({ currentCard, data, type, i }) => {
  if (type === ANTES_APLICACION) {
    return <CardAplicacion currentCard={currentCard} i={i} data={data} />;
  }
  if (type === PREPARACION_PACIENTE) {
    return <CardPreparacion currentCard={currentCard} i={i} data={data} />;
  }
  if (type === ANTECEDENTES) {
    return <CardAntecedente i={i} currentCard={currentCard} data={data} />;
  }
};

const CardAntecedente = ({ data, currentCard, i }) => {
  const [classSpecial, setClassSpecial] = useState("");

  useEffect(() => {
    if ((currentCard === 2 && i === 0) || (currentCard === 0 && i === 2)) {
      setClassSpecial("opacity-0");
    } else {
      setClassSpecial("");
    }
    // eslint-disable-next-line
  }, [currentCard]);

  return (
    <div
      className={` ${classSpecial} sm:w-64 lg:w-80 lg:h-96 lg:flex lg:flex-col lg:justify-center  sm:h-64   iphone67:h-96  xl:w-minfull sm:h-60 lg:h-80	xl:h-150  bg-white rounded-xl sm:px-8 lg:px-8 xl:px-8 xl:-mt-10 iphone12:-mt-10`}
    >
      <div className=" sm:mb-5 lg:mb-1 sm:h-2/6 xl:h-3/6  flex justify-center items-center">
        <img
          src={Img.lis}
          alt="avatar"
          className=" sm:mt-4 lg:mt-0 sm:w-20 sm:h-20 iphone67:w-28 iphone67:h-28 lg:w-34 lg:h-34	xl:h-32 xl:w-32 rounded-full  border-8 border-bluecorp border-white	"
        />
      </div>
      <div className="h-3/6 xl:-mt-3">
        <h4 className="sm:text-sm iphone67:text-lg lg:text-xl xl:text-xl	 text-center Gilroy-Medium ">
          {data.title}
        </h4>
        <div className=" sm:text-xs text-center iphone67:text-tiny xl:text-base Inter mt-2 lg:mt-4 font-light ">
          <p>{data.subtitle}</p>
          <p className="mt-2 xl:mt-5">{data.subsubtitle}</p>
        </div>
      </div>
    </div>
  );
};

const CardAplicacion = ({ data, currentCard, i }) => {
  const [classSpecial, setClassSpecial] = useState("");

  useEffect(() => {
    if ((currentCard === 0 && i === 4) || (currentCard === 4 && i === 0)) {
      setClassSpecial("opacity-0");
    } else {
      setClassSpecial("");
    }
    // eslint-disable-next-line
  }, [currentCard]);

  return (
    <div
      className={` ${classSpecial} sm:w-72 md:h-72  lg:w-80  lg:h-96 lg:flex lg:flex-col lg:justify-center mt-8   md:-mt-5 iphone67:-mt-5   iphone67:h-96  xl:w-minfull sm:h-60 	xl:h-150 flex flex-col bg-white rounded-xl sm:px-8 lg:px-8 xl:px-8 xl:-mt-10 iphone12:-mt-10`}
    >
      <div className="w-full flex-1   Gilroy-Medium flex justify-center items-center">
        <h4 className=" text-base lg:text-xl text-center "> {data.title} </h4>
      </div>
      <div className="flex-2  flex justify-center  items-center p-0">
        <img
          src={data.imagen}
          alt="imagen"
          className=" sm:w-24 h-auto  lg:w-32 lg:h-auto xl:w-32	xl:h-32 	"
        />
      </div>
      <div className="flex-3 lg:flex-2  xl:flex-3 lg:-mt-0 flex flex-col justify-start ">
        <div className="  Inter font-light mt-4">
          <p className={`xl:text-base sm:text-xs md:text-sm  text-center`}>
            {data.subtitle}
          </p>
          <p className={`xl:text-base sm:text-xs md:text-sm  text-center`}>
            {data.subsubtitle}
          </p>
        </div>
      </div>
    </div>
  );
};

const CardPreparacion = ({ data, currentCard, i }) => {
  const [classSpecial, setClassSpecial] = useState("");

  useEffect(() => {
    if ((currentCard === 0 && i === 2) || (currentCard === 2 && i === 0)) {
      setClassSpecial("opacity-0");
    } else {
      setClassSpecial("opacity-1");
    }
    // eslint-disable-next-line
  }, [currentCard]);

  return (
    <div
      style={{
        boxShadow: 0,
      }}
      className={` ${classSpecial} sm:w-72 md:h-72 md:w-72 lg:w-80  lg:flex lg:flex-col lg:justify-center  xl:w-minfull sm:h-60 lg:h-80	xl:h-122  bg-white rounded-xl px-10 xl:-mt-10 iphone12:-mt-10`}
    >
      <div className="md:h-3/6 xl:h-3/6  flex justify-center items-center">
        <img
          src={data.imagen}
          alt="avatar"
          className={`sm:w-20 sm:h-20  md:w-32 lg:w-32 ${
            currentCard === 2 ? "xl:w-36" : "xl:w-40"
          }  md:h-auto  	xl:h-auto`}
        />
      </div>
      <div className="md:h-3/6 xl:h-3/6 md:-mt-4 flex flex-col justify-start">
        <h4 className="text-xl xl:text-xl text-center Gilroy-Medium">
          {data.title}
        </h4>
        <div className="Inter font-light md:mt-2 xl:mt-4 text-center sm:text-xs md:text-z xl:text-base ">
          <p>{data.subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
