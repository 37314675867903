import React, { useEffect }  from 'react'
import { Img } from '../../types';
import {TimelineMax} from "gsap"

const BotellaAnimation = (props)=>{

    useEffect(()=>{
        const c1 = document.getElementById("c1")  
        const c2 = document.getElementById("c2")
        const c3 = document.getElementById("c3")    
        const c4 = document.getElementById("c4")  
        // eslint-disable-next-line
        var tl = new TimelineMax({ repeat: 0 })
        .to(c1,1,{opacity: 1,
          onComplete:()=>{
          }})
        .to(c2,1,{opacity: 1,onComplete:()=>{
          c1.style.opacity = 0;
        }})
        .to(c3,1,{opacity: 1,onComplete:()=>{
          c2.style.opacity = 0;
        }})
        .to(c4,1,{opacity: 1,onComplete:()=>{
          c3.style.opacity = 0;
        }})
        .to(c4,1.5,{opacity: 1,onComplete:()=>{
          c3.style.opacity = 0;
          props.finalFunction()
        }})
    })

    return(
        <>   
        <div className=" flex justify-center items-center">
            <img id="c1" className="w-12 absolute opacity-0 object-contain" src={Img.frasco11} alt=""/>
            <img id="c2" className="w-12 absolute opacity-0 object-contain" src={Img.frasco21} alt=""/>
            <img id="c3" className="w-12 absolute opacity-0 object-contain" src={Img.frasco31} alt=""/>
            <img id="c4" className="w-12 absolute opacity-0 object-contain" src={Img.frasco41} alt=""/>
        </div>
       </>
    );
}

export default BotellaAnimation;