import React, { useEffect } from "react";
import ButtonFooter from "../general/ButtonFooter";
import { useHistory } from "react-router-dom";
import { Img, Rutas } from "../../types";

const SplashDiagnostico = () => {
  useEffect( () => {
    window.dataLayer.push({
      event: 'initialization',
      biib_asset_code: 'other|mx|biogenlinc',
      biib_name: 'biogenlinc - simulador lis',
      biib_region: 'latam',
      biib_language: 'es',
      biib_country: 'mx',
      biib_env: 'prod',
      spaWebsite: true,
     } );
    window.dataLayer.push({
      event: 'virtualPageview',
      virtualPageURL: '/lis/diagnostico',
      virtualPageTitle: 'Lis App | Diagnosis Page',
      spaWebsite: true,
      contentType: "medical education",
      format: "application",
      contentID:"undefined",
      therapyArea:"sma",
      is_promotional:'non-promotional',
      is_scientific_content: 'No',
      keywords: "",
      product: 'no product',
      target_audience: 'hcp',
      visitorLoginState: 'anonymous',
      pageCategoryLevel1:"lys|application",
      biogenEmail:"FALSE",
      videoLive:"FALSE"
    } );
  }, [])
  
  const history = useHistory();

  return (
    <div className="w-full fixed inset-0 h-full p-6 iphone67:p-11 xl:p-11 animate-fadein degrade_blue">
      <div className="w-full h-full  justify-start">
        <div className="z-40 absolute ">
          <h1 className="z-40 sm:text-base md:text-lg lg:text-xl xl:text-2xl text-blackSubtitle	text-justify Gilroy-Light">
            Diagnóstico
          </h1>
          <h2 className="z-40 sm:text-xl md:text-two iphone67:text-twohalf lg:text-2xl xl:text-4xl text-bluecorp	text-start Gilroy-Medium">
            Atrofia Muscular Espinal Infantil
          </h2>
        </div>
        <div className=" w-full fixed inset-0 left-0 top-0 ">
          <div className=" w-full fixed inset-0 ">
            <img
              className="w-full fixed inset-0 xl:mt-4  object-contain"
              src={Img.lisColumnaBlur}
              alt=""
            />
          </div>
          <div className="absolute w-full h-full ">
            <img
              className=" w-full h-full xl:mt-4 object-contain"
              src={Img.lisColumna}
              alt=""
            />
          </div>
        </div>
      </div>
      <div></div>
      <ButtonFooter
        onClick={() => history.push(Rutas.ANTES_DE_APLICACION)}
        title="Comenzar con la preparación"
      />
    </div>
  );
};

export default SplashDiagnostico;
