import { createContext } from "react";

const generalContext = createContext({
  nextAudio:Function,
        changeStateAudio:Function,
        changeInteractionState:Function,
        blockRoute:Function,
        verifyRoute:Function,
        changeFullScreen:Function,
});

export default generalContext;
