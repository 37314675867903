import React, { useEffect, useState, useRef } from "react";
import ReactCardCarousel from "react-card-carousel";
import { useSwipeable } from "react-swipeable";
import { useHistory } from "react-router-dom";
import Card from "../components/pasos/Card";
import ButtonFooter from "../components/general/ButtonFooter";
import Slider from "../components/pasos/Slider";
import {
  ANTES_APLICACION,
  PREPARACION_PACIENTE,
  ANTECEDENTES,
  Rutas,
} from "../types";
import {
  antecedentes,
  antesdeaplicacion,
  preparacion,
} from "../data/dataAntecedentes";
// import CardAplicacion from "../components/pasos/CardAplicacion";
const Pasos = ( { type } ) => {
  
  const history = useHistory();
  const [currentCard, setCurrentCard] = useState(0);
  const [cards, setCards] = useState(preparacion);
  const [principalTitle, setPrincipalTitle] = useState("");
  const [butonTitle, setButtonTitle] = useState("Continuar");
  const [nextUrl, setNextUrl] = useState("");
  const carrusel = useRef();

  const onClickButton = () => {
    const current = carrusel.current.getCurrentIndex();
    if (current === cards.length - 1) {
      setCurrentCard(0);
      history.push(nextUrl);
    } else {
      carrusel.current.next();
    }
  };

  // Se encarga de evitar retroceso al inicio
  const afterChange = (e) => {
    const current = carrusel.current.getCurrentIndex();
    const last = cards.length - 1;
    if (currentCard === 0 && current === last) {
      carrusel.current.goTo(0);
      return;
    }
    if (current === 0 && currentCard === last) {
      setCurrentCard(currentCard);
      carrusel.current.goTo(currentCard);
      return;
    }
    setCurrentCard(current);
  };
  useEffect( () => {
    
    
    
  }, [])
    useEffect(() => {
    if (cards.length !== 0) {
      setCurrentCard(0);
      setButtonTitle(cards[0].titleButton);
    }
  }, [cards]);
  useEffect(() => {
    if (cards.length !== 0) {
      setButtonTitle(cards[currentCard].titleButton);
    }
    // eslint-disable-next-line
  }, [currentCard]);
  useEffect(() => {
    setCurrentCard(0);
  }, [type]);
  useEffect(() => {
    if( type === ANTECEDENTES )
    {
      window.dataLayer.push({
        event: 'initialization',
        biib_asset_code: 'other|mx|biogenlinc',
        biib_name: 'biogenlinc - simulador lis',
        biib_region: 'latam',
        biib_language: 'es',
        biib_country: 'mx',
        biib_env: 'prod',
        spaWebsite: true,
       } );
      window.dataLayer.push({
        event: 'virtualPageview',
        virtualPageURL: '/lis/antecedentes',
        virtualPageTitle: 'Lis App | Medical History',
        spaWebsite: true,
        contentType: "medical education",
        format: "application",
        contentID:"undefined",
        therapyArea:"sma",
        is_promotional:'non-promotional',
        is_scientific_content: 'No',
        keywords: "",
        product: 'no product',
        target_audience: 'hcp',
        visitorLoginState: 'anonymous',
        pageCategoryLevel1:"lys|application",
        biogenEmail:"FALSE",
        videoLive:"FALSE"
      } );
      setCards(antecedentes);
      setPrincipalTitle("Antecedentes");
      setNextUrl(Rutas.SPLASH1);
    }
    if( type === ANTES_APLICACION )
    {
      window.dataLayer.push({
        event: 'initialization',
        biib_asset_code: 'other|mx|biogenlinc',
        biib_name: 'biogenlinc - simulador lis',
        biib_region: 'latam',
        biib_language: 'es',
        biib_country: 'mx',
        biib_env: 'prod',
        spaWebsite: true,
       } );
      window.dataLayer.push( {
        event: 'virtualPageview',
        virtualPageURL: '/lis/antes',
        virtualPageTitle: 'Lis App | Previous',
        spaWebsite: true,
        contentType: "medical education",
        format: "application",
        contentID:"undefined",
        therapyArea:"sma",
        is_promotional:'non-promotional',
        is_scientific_content: 'No',
        keywords: "",
        product: 'no product',
        target_audience: 'hcp',
        visitorLoginState: 'anonymous',
        pageCategoryLevel1:"lys|application",
        biogenEmail:"FALSE",
        videoLive:"FALSE"
      } );
      setCards(antesdeaplicacion);
      setPrincipalTitle("Antes de la aplicación");
      setNextUrl(Rutas.PREPARACION);
    }
    if( type === PREPARACION_PACIENTE )
    {
      window.dataLayer.push({
        event: 'initialization',
        biib_asset_code: 'other|mx|biogenlinc',
        biib_name: 'biogenlinc - simulador lis',
        biib_region: 'latam',
        biib_language: 'es',
        biib_country: 'mx',
        biib_env: 'prod',
        spaWebsite: true,
       } );
      window.dataLayer.push({
        event: 'virtualPageview',
        virtualPageURL: '/lis/preparacion',
        virtualPageTitle: 'Lis App | Patient set up',
        spaWebsite: true ,
        biogenEmail:false,
      contentType: "",
      format: "application",
      is_promotional:'non-promotional',
      is_scientific_content: 'TBD',
      keywords: "",
      pageCategoryLevel1: "lys",
      product: 'TBD',
      targetAudiences: 'hcp'

,
      therapyAreas: 'sma',
      visitorLoginState: 'anonymous'      
      } );
      console.log("PREPARACION_PACIENTE", { preparacion });
      setCards(preparacion);
      setPrincipalTitle("Preparación del paciente");
      setNextUrl(Rutas.SPLASH2);
      carrusel.current.goTo(0);
    }
    // eslint-disable-next-line
  }, [type]);

  //Se encarga de detectar el swipe
  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      carrusel.current.next();
    },
    onSwipedRight: () => {
      carrusel.current.prev();
    },
  });

  return (
    <div className="bg-white fixed inset-0">
      <div className="degrade_blue  animate-fadein fixed inset-0  h-full  relative w-full flex flex-col ">
        <div className="h-1/6 w-full 	xl:mt-3 iphone11:-mt-2 iphone12:-mt-2   flex  justify-center items-center">
          <div>
            <h1 className=" md:text-xl xl:text-25xl  iphone67:mb-5  text-blackcorp  Gilroy-Medium ">
              {principalTitle}
            </h1>
          </div>
        </div>
        <div
          {...handlers}
          className=" h-auto w-screen  flex flex-col min-w-screen"
        >
          <ReactCardCarousel
            ref={carrusel}
            afterChange={afterChange}
            disable_keydown={true}
            className="bg-black"
            // disable_box_shadow=
            style={{
              borderRadius: 20,
              marginTop: -30,
            }}
            spread={"narrow"}
          >
            {cards.map((data, i) => (
              <Card
                style={{
                  opacity: 0,
                }}
                currentCard={currentCard}
                i={i}
                key={data.id}
                data={data}
                type={type}
              />
            ))}
          </ReactCardCarousel>
        </div>
        <div className="absolute w-full h-28 md:h-32 lg:h-32 xl:h-32  iphone67:h-36 flex justify-center bottom-0 ">
          <Slider ammount={cards} position={currentCard} />
        </div>
        <div>
          <ButtonFooter onClick={onClickButton} title={butonTitle} />
        </div>
        {type === ANTECEDENTES?
        <div className="fixed bottom-0 text-center w-full">
          <p className="text-xxs text-white">Las imágenes usadas son casos ficticios y no relacionados con casos reales.</p></div>
        :<div></div>
        }
      </div>
    </div>
  );
};

export default Pasos;
