import React, { useEffect, useReducer } from "react";
import GeneralContext from "./general.context";
import GeneralReducer from "./general.reducer";
import { audios, subtitulos } from "../../data/dataAudios";
import {
  CHANGE_AUDIO_STATE,
  CHANGE_INTERACTION_STATE,
  NEXT_AUDIO,
  BLOCK_ROUTE,
  RESET_EXPERIENCIA,
  initalRoutesBlockedState,
  SET_ROUTES_BLOCKED_STORE,
  CHANGE_FULLSCREEN_STATE,
  CHANGE_MUTED_STATE
} from "../../types";

const GeneralState = ({ children }) => {
  const indexAudios = 0;

  const initialState = {
    userName: "Mamá de Lis",
    audios,
    subtitulos,
    totalAudios: audios.length,
    indexAudios,
    currentSubtitle: subtitulos[indexAudios],
    currentAudio: audios[indexAudios],
    bloquearRutas: false,
    initalRoutesBlockedState,
    isAudioFinish: false,
    isInteractionFinish: true,
    isFullScreen: false,
    isMuted:false,
  };

  const [state, dispatch] = useReducer(GeneralReducer, initialState);

  useEffect(() => {
    setRoutesBlockedState();
  }, []);

  const nextAudio = () => {
    dispatch({ type: NEXT_AUDIO });
  };

  const resetExperiencia = () => {
    dispatch({ type: RESET_EXPERIENCIA });
  };

  /**
   *Esta funcióń cambia el audio que está reproduciendose
   * 
   * @param {String} audioState
   * @returns {null}
   */
  const changeStateAudio = (audioState) => {
    dispatch({ type: CHANGE_AUDIO_STATE, payload: audioState });
  };
  const changeMutedstate = (mutedState) => {
    dispatch({ type: CHANGE_MUTED_STATE, payload: mutedState });
  };

  const changeFullScreen = (payload) => {
    // console.log("Cambiando a fullscreen")
    dispatch({ type: CHANGE_FULLSCREEN_STATE, payload: payload });
  };

  const changeInteractionState = (interctionState) => {
    dispatch({ type: CHANGE_INTERACTION_STATE, payload: interctionState });
  };

  const blockRoute = (route) => {
    dispatch({
      type: BLOCK_ROUTE,
      payload: route,
    });
  };
  const setRoutesBlockedState = () => {
    dispatch({
      type: SET_ROUTES_BLOCKED_STORE,
    });
  };

  const verifyRoute = (route) => {
    if (!state.bloquearRutas) {
      return false;
    }
    let status = false;
    for (const property in state.initalRoutesBlockedState) {
      if (
        property === route &&
        state.initalRoutesBlockedState[property] === true
      ) {
        status = true;
      }
    }
    return status;
  };

  return (
    <GeneralContext.Provider
      value={{
        audios: state.audios,
        currentSubtitle: state.currentSubtitle,
        currentAudio: state.currentAudio,
        indexAudios: state.indexAudios,
        totalAudios: state.totalAudios,
        userName: state.userName,
        isAudioFinish: state.isAudioFinish,
        isInteractionFinish: state.isInteractionFinish,
        isFullScreen: state.isFullScreen,
        isMuted:state.isMuted,
        nextAudio,
        resetExperiencia,
        changeStateAudio,
        changeInteractionState,
        blockRoute,
        verifyRoute,
        changeFullScreen,
        changeMutedstate
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

export default GeneralState;
