import React, { useContext, useEffect, useState } from "react";
import ButtonFooter from "../general/ButtonFooter";
import { Img, Rutas } from "../../types";
import { useHistory } from "react-router-dom";
import Config from "../../config";
import generalContext from "../../stores/general/general.context";

const SplashGire = Img.splashGire;
const SplashContinuar = ( { handleFullScreen } ) => {
  
  let history = useHistory();
  const { resetExperiencia } = useContext(generalContext)
  const [landscape, setLandscape] = useState(false);

  useEffect( () => {
    window.dataLayer.push({
      event: 'initialization',
      biib_asset_code: 'other|mx|biogenlinc',
      biib_name: 'biogenlinc - simulador lis',
      biib_region: 'latam',
      biib_language: 'es',
      biib_country: 'mx',
      biib_env: 'prod',
      spaWebsite: true,
     } );
    window.dataLayer.push({
      event: 'virtualPageview',
      virtualPageURL: '/lis/girar',
      virtualPageTitle: 'Lis App | Rotate Page',
      spaWebsite: true,
      contentType: "medical education",
      format: "application",
      contentID:"undefined",
      therapyArea:"sma",
      is_promotional:'non-promotional',
      is_scientific_content: 'No',
      keywords: "",
      product: 'no product',
      target_audience: 'hcp',
      visitorLoginState: 'anonymous',
      pageCategoryLevel1:"lys|application",
      biogenEmail:"FALSE",
      videoLive:"FALSE"
      
    });
    risezed();
  });

  useEffect(() => {
    if (landscape) {
      // refAudio.current.play();
      resetExperiencia();
      history.push( Rutas.EXPERIENCIA );
      

    } else {
      // refAudio.current.pause();}
      // console.log("portrait");
    }
    // eslint-disable-next-line
  }, [landscape]);

  window.addEventListener("resize", () => risezed());

  const risezed = () => {
    if (Config.isMovile() !== "iPhone") {
      if (window.innerWidth > window.innerHeight) {
        setLandscape( true );
      } else {
        setLandscape(false);
      }
    }
  };

  return (
    <>
      <div className=" landscape:hidden  w-full h-full  relative inset-0  p-11 flex animate-fadein justify-center   items-center  degrade_blue">
        <div className="w-full  flex flex-col sm:-mt-16  ">
          <div className="h-1/6">
            <h3 className="sm:text-base md:text-lg lg:text-xl xl:text-2xl	text-center  Gilroy-Medium">
              Gire su teléfono para continuar
            </h3>
          </div>
          <div className="  my-5 flex justify-center items-center">
            <img
              src={SplashGire}
              className="sm:h-3/6 w-4/6 xl:h-3/6"
              alt="continuar"
            />
          </div>
          <div className="h-1/6">
            <h3 className="sm:text-base md:text-lg lg:text-xl xl:text-2xl	text-center  Gilroy-Medium">
              Use sus audífonos para una mejor experiencia
            </h3>
          </div>
          <div className="h-20"></div>
          <div className="h-1/6 ">
        <p className="text-xxs text-white text-justify">Las imágenes y simulación están adaptadas para su uso en teléfono celular
               con propósitos de información científica, el procedimiento en vivo debe realizarse
                por un profesional con experiencia en el procedimiento y en las condiciones adecuadas.
                 Las imágenes de simulación corresponden a modelos pediátricos, sin alteraciones morfológicas
                  de la columna como escoliosis o lordosis.</p>
        </div>
        </div>
        <ButtonFooter
          onClick={() => {
            history.push(Rutas.EXPERIENCIA);
            resetExperiencia();
          }}
          title="Iniciar simulación"
        />
      </div>
      <div className="w-full fixed inset-0  degrade_blue   hidden landscape:flex justify-center items-center">
        <div className="relative w-full mt-32">
          <ButtonFooter
            onClick={() => {
              resetExperiencia();
              history.push( Rutas.EXPERIENCIA );
              try {
                handleFullScreen.enter()
              } catch (error) {
              }
            }}
            title="Iniciar simulación"
          />
        </div>
      </div>
    </>
  );
};

export default SplashContinuar;
