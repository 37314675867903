import React from "react";
import { Img } from "../../types";

const Header = () => {

  

  console.log("Header");
  return (
    <div className="h-16 fixed z-40 top-0	w-full bg-white shadow_header__chat flex justify-between items-center px-7">
      <h3 className="text-lg text-blackcorp	"> Mamá de Lis </h3>
      <img className="h-10 w-10 rounded-full	" alt="avatar" src={Img.avatar} />
    </div>
  );
};

export default React.memo(Header);
