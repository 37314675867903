import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Img } from "../../types";

const union = Img.union

const MessageRight = ({ data }) => {
  const { message, name } = data;
  const refMessage = useRef();
  const timeCheck = 1000;

  const [isCheckVisible, setIsCheckVisible] = useState(false);
  useEffect(() => {
    // Posiciona el mensaje a la vista cuando se genera
    refMessage.current.scrollIntoView({
      behavior: "smooth",
    });
    setTimeout(() => {
      setIsCheckVisible(true);
    }, timeCheck);
  }, []);
  console.log(data.id, ": derecho");

  return (
    <div
      ref={refMessage}
      className="w-full  mt-2.5 animate-popupmessage mb-3	flex flex-col justify-end items-end"
    >
      <div className="w-10/12 h-5/6 bg-celestedeg p-5 sm:text-xs xl:text-sm	 shadow-lg rounded-tl-xl rounded-tr-xl rounded-bl-xl	">
        {message}
      </div>
      <div className="text-bluecorp font-light h-1/6 flex justify-center items-center  text-right text-xs mt-2.5	">
        <b className="	"> {name} </b>{" "}
        <span className="ml-1">Yo {moment().format("LT")} </span>
        <img
          className={isCheckVisible ? "ml-1 animate-fadein" : "ml-1 opacity-0"}
          src={union}
          alt="flecha"
        />
      </div>
    </div>
  );
};

export default React.memo(MessageRight);
