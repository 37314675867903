import React, { useContext, useEffect, useRef, useState } from "react";
import GeneralContext from "../stores/general/general.context";
import { useHistory } from "react-router-dom";
import { mensajes } from "../data/dataMensajes";
import { DOCTOR, Rutas, USUARIO, Audio } from "../types/index";
import Header from "../components/chat/Header";
import MessageLeft from "../components/chat/MessageLeft";
import MessageRight from "../components/chat/MessageRight";
import ButtonFooter from "../components/general/ButtonFooter";
import Waiting from "../components/chat/waiting";

const Chat = () => {
  

  const { blockRoute, verifyRoute, analyticsLog } = useContext(GeneralContext);

  let history = useHistory();
  const timeDelayMessage = 3000;
  const timeDelayWaiting = 3000;
  const refContainer = useRef();
  const refContainerContainer = useRef()
  const totalMessages = mensajes.length;
  const refAudio = useRef();
  const refHoy = useRef();

  const [waiting, setWaiting] = useState(false);
  const [messagesOnDisplay, setmessagesOnDisplay] = useState([]);
  const [currentMessage, setCurrentMessage] = useState(0);
  const [buttonmessage, setButtonMessage] = useState(null);

  const onClickNext = () => {
    if (currentMessage !== totalMessages) {
      getNextMessage();
    } else {
      blockRoute("ANTECEDENTES");
      history.push(Rutas.ANTECEDENTES);
      // analyticsLog({ title: "antecedentes", message: "entro a antecedentes" });
    }
  };

  const getNextMessage = () => {
    const next = currentMessage + 1;
    const lastMessage = mensajes[currentMessage];
    lastMessage.hora = getTime();
    setmessagesOnDisplay([...messagesOnDisplay, lastMessage]);
    setCurrentMessage(next);
    setButtonMessage(lastMessage.messagebutton);

    if (
      (lastMessage.messagebutton && next !== totalMessages) ||
      currentMessage === 0
    ) {
      setWaiting(false);
      setTimeout(() => {
        setWaiting(true);
      }, timeDelayWaiting);
    } else {
      setWaiting(false);
    }
  };
  useEffect( () => {
    
    const status = verifyRoute("CHAT");
    if (status && history) {
      history.goBack();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(currentMessage >= 1){
      window.dataLayer.push({
        event: 'initialization',
        biib_asset_code: 'other|mx|biogenlinc',
        biib_name: 'biogenlinc - simulador lis',
        biib_region: 'latam',
      biib_language: 'es',
      biib_country: 'mx',
      biib_env: 'prod',
      spaWebsite: true,
    } );
    window.dataLayer.push({
      event: 'virtualPageview',
      virtualPageURL: `/lis/chat/page${currentMessage}`,
      virtualPageTitle: 'Lis App | Chat',
      spaWebsite: true,
      contentType: "medical education",
      format: "application",
      contentID:"undefined",
      therapyArea:"sma",
      is_promotional:'non-promotional',
      is_scientific_content: 'No',
      keywords: "",
      product: 'no product',
      target_audience: 'hcp',
      visitorLoginState: 'anonymous',
      pageCategoryLevel1:"lys|application",
      biogenEmail:"FALSE",
      videoLive:"FALSE"
    } );
  }

  }, [currentMessage])

  useEffect(() => {
    if (!buttonmessage) {
      if (currentMessage !== 0) {
        setTimeout(() => {
          setWaiting(true);
        }, timeDelayWaiting);
      }
      setTimeout(() => {
        // eslint-disable-next-line
        getNextMessage();
        refAudio.current.play();
      }, timeDelayMessage);
    }
    // eslint-disable-next-line
  }, [buttonmessage]);

  return (
    <div className="bg-white fixed inset-0 h-full">
      <div className="fixed inset-0 h-full degrade_blue w-full  relative animate-fadein">
        <Header />
        <audio preload="auto" ref={refAudio}>
          <source src={Audio.Mensaje} type="audio/mpeg" />
        </audio>
        <div
          className="w-full h-full 	  overflow-y-auto "
          ref={refContainerContainer}
        >
          <div
            className="w-full h-custom px-9 pb-4 pt-10 overflow-y-auto  "
            ref={refContainer}
          >
            <div
              ref={refHoy}
              className="h-16  mt-6 mb-8 text-graycorp opacity-50 text-lg flex justify-center items-end"
            >
              <span>Hoy</span>
            </div>
            {/* eslint-disable-next-line */}
            {messagesOnDisplay.map((mensaje) => {
              if (mensaje.type === USUARIO) {
                return <MessageLeft key={mensaje.id} data={mensaje} />;
              } else if (mensaje.type === DOCTOR) {
                return <MessageRight key={mensaje.id} data={mensaje} />;
              }
            })}
            {waiting && <Waiting />}
          </div>
          {buttonmessage && waiting && (
            <ButtonFooter title={buttonmessage} onClick={onClickNext} />
          )}
          {currentMessage === totalMessages && (
            <ButtonFooter title={buttonmessage} onClick={onClickNext} />
          )}
        </div>
      </div>
    </div>
  );
};

const getTime = () => {
  const today = new Date();
  let time = today.getHours() + ":";
  let minutes =
    today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
  time += minutes;
  const ampm = today.getHours() > 12 ? "pm" : "am";
  return (time += " " + ampm);
};

export default Chat;
