import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Img, Rutas } from "../../../types";
const VideoC = () => {


  useEffect(() => {
    
    
    window.dataLayer.push({
      event: 'initialization',
      biib_asset_code: 'other|mx|biogenlinc',
      biib_name: 'biogenlinc - simulador lis',
      biib_region: 'latam',
      biib_language: 'es',
      biib_country: 'mx',
      biib_env: 'prod',
      spaWebsite: true,
    } );

    window.dataLayer.push({
      event: 'virtualPageview',
      virtualPageURL: '/lis/splash',
      virtualPageTitle: 'Lis App | Splash',
      spaWebsite: true,
      contentType: "medical education",
      format: "application",
      contentID:"undefined",
      therapyArea:"sma",
      is_promotional:'non-promotional',
      is_scientific_content: 'No',
      keywords: "",
      product: 'no product',
      target_audience: 'hcp',
      visitorLoginState: 'anonymous',
      pageCategoryLevel1:"lys|application",
      biogenEmail:"FALSE",
      videoLive:"FALSE"
    } );
    
    
    


  }, [])
  

  let history = useHistory();
  const [off, setOff] = useState(true);
  const [step, setStep] = useState(0);
  const timedelay = 3000;
  useEffect(() => {
    setTimeout(() => {
      setOff(false);
      setTimeout(() => {
        setStep(1);
        setTimeout(() => {
          setOff(true);
          setTimeout(() => {
            history.push(Rutas.INICIO);
          }, 800);
        }, timedelay);
      }, 800);
    }, timedelay);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="fixed inset-0 bg-black">
        <div className="background_placeholder bg-black h-full w-full ">
        {true && (
            <div className="h-1/3 flex w-full justify-end">
              <p className="pr-6 pt-6 text-sm text-white">Biogen-120143</p>
            </div>
          )}
          {step === 0 && (
            <div className="h-1/3 w-full flex items-center justify-center">
            <img
              src={Img.logobiogen}
              className={`${
                off ? "animate-fadein " : "animate-fadeout "
              }  w-8/12`}
              alt="img de logo"
              />
              </div>
          )}
          {step === 1 && (
            <div className="h-1/3 w-full flex items-center justify-center">
              <img
              src={Img.logolis}
              className={`${
                off ? "animate-fadeout" : " animate-fadein "
              } w-7/12 `}
              alt="img logo"
            />
            </div>
          )}
          {step===0 && (
            <div className="h-1/3 pb-4 flex w-full justify-center items-end">
            <div>
            <p className="text-3xxs text-white text-center">El material contenido en sólo para uso de profesionales de la salud, 
            Prohibida la reproducción total o parcial. Derechos reservados.</p>
            <p className="text-3xxs text-white text-justify px-3">Los diálogos aquí mostrados, son pre-estableciodos, sin que se tenga función 
            de comunicación en tiempo real, si Usted tiene alguna pregunta, relacionada con el contenido de este 
            simulador, por favor comuníquese con el Equipo Médico de Biogen </p>
            </div>
          </div>
          )}
          {step===1 && (
            <div className="h-1/3 pb-4 flex w-full justify-center items-end">
            <div>
            <p className="text-3xxs text-white text-center">El  caso clínico y las conversaciones son hipotéticas y tienen como 
            objetivo entregar información científica </p>
            </div>
          </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VideoC;
