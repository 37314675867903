import React, { useEffect } from 'react'
import { Img } from '../../types';
import {TimelineMax} from "gsap"


const TargetAnimation = (props)=>{
    useEffect(()=>{
        const c2 = document.getElementById("c2")
        const c3 = document.getElementById("c3")   
        // eslint-disable-next-line 
        var tl = new TimelineMax({ repeat: -1 })
        .to(c2,0.8,{opacity: 1})
        .to(c3,0.8,{opacity: 1})
        .to(c3,0.8,{opacity: 0.1})
        .to(c2,0.8,{opacity: 0.1})
    })
    return (
       <div className="w-full h-full  flex justify-center items-center ">
           {props.isok 
                ? <img id="c1" className="absolute handle   " src={Img.ellipse1ok} alt=""/>
                : <img id="c1" className="absolute handle " src={Img.ellipse1} alt=""/>
           }
           <img id="c2" className="absolute opacity-5  " src={Img.ellipse2} alt=""/>
           <img id="c3" className="absolute opacity-5 " src={Img.ellipse3} alt=""/>
       </div>
    );
}
export default TargetAnimation;