import React, { useContext, useEffect, useState } from "react"
import IntefazExp from "../components/experiencia/Interfaz"
import SliderExp from "../components/experiencia/SliderExp"
import GeneralContext from "../stores/general/general.context"
import { Video, Img, Rutas } from "../types"
import { gsap, TimelineMax } from "gsap"
import generalContext from "../stores/general/general.context"
import Draggable from "react-draggable"
import TargetAnimation from "../components/chat/TargetComponent"
import { useHistory } from "react-router"
import BotellaAnimation from "../components/animations/BotellaAnimationComponent"
import Config from "../config"
import AlertSwitchToHorizontal from "../components/experiencia/AlertaSwitchToHorizontal"

// import interact from "interactjs"

const Experiencia = ({ handleFullScreen }) => {
  let history = useHistory()
  const { indexAudios, isAudioFinish } = useContext(GeneralContext)
  const [nextButtonVisible, setNextButtonVisible] = useState(false)

  useEffect(() => {
    if( indexAudios === 0 )
    {
      window.dataLayer.push({
        event: 'initialization',
        biib_asset_code: 'other|mx|biogenlinc',
        biib_name: 'biogenlinc - simulador lis',
        biib_region: 'latam',
        biib_language: 'es',
        biib_country: 'mx',
        biib_env: 'prod',
        spaWebsite: true,
       } );
      window.dataLayer.push({
        event: 'virtualPageview',
        virtualPageURL: '/lis/simulador/page1',
        virtualPageTitle: 'Lis App | Simulator - Page 1',
        spaWebsite: true,
        contentType: "medical education",
      format: "application",
      contentID:"undefined",
      therapyArea:"sma",
      is_promotional:'non-promotional',
      is_scientific_content: 'No',
      keywords: "",
      product: 'no product',
      target_audience: 'hcp',
      visitorLoginState: 'anonymous',
      pageCategoryLevel1:"lys|application",
      biogenEmail:"FALSE",
      videoLive:"FALSE"
        })
      }
    if( indexAudios === 1 )
    {
      window.dataLayer.push({
        event: 'initialization',
        biib_asset_code: 'other|mx|biogenlinc',
        biib_name: 'biogenlinc - simulador lis',
        biib_region: 'latam',
        biib_language: 'es',
        biib_country: 'mx',
        biib_env: 'prod',
        spaWebsite: true,
       } );
      window.dataLayer.push({
        event: 'virtualPageview',
        virtualPageURL: '/lis/simulador/page2',
        virtualPageTitle: 'Lis App | Simulator - Page 2',
        spaWebsite: true,
        contentType: "medical education",
        format: "application",
        contentID:"undefined",
        therapyArea:"sma",
        is_promotional:'non-promotional',
        is_scientific_content: 'No',
        keywords: "",
        product: 'no product',
        target_audience: 'hcp',
        visitorLoginState: 'anonymous',
        pageCategoryLevel1:"lys|application",
        biogenEmail:"FALSE",
        videoLive:"FALSE"
        })
      }
    if( indexAudios === 2 )
    {
      window.dataLayer.push({
        event: 'initialization',
        biib_asset_code: 'other|mx|biogenlinc',
        biib_name: 'biogenlinc - simulador lis',
        biib_region: 'latam',
        biib_language: 'es',
        biib_country: 'mx',
        biib_env: 'prod',
        spaWebsite: true,
       } );
      window.dataLayer.push({
        event: 'virtualPageview',
        virtualPageURL: '/lis/simulador/page3',
        virtualPageTitle: 'Lis App | Simulator - Page 3',
        spaWebsite: true,
        contentType: "medical education",
      format: "application",
      contentID:"undefined",
      therapyArea:"sma",
      is_promotional:'non-promotional',
      is_scientific_content: 'No',
      keywords: "",
      product: 'no product',
      target_audience: 'hcp',
      visitorLoginState: 'anonymous',
      pageCategoryLevel1:"lys|application",
      biogenEmail:"FALSE",
      videoLive:"FALSE"
     
        })
      }
    if( indexAudios === 3 )
    {
      window.dataLayer.push({
        event: 'initialization',
        biib_asset_code: 'other|mx|biogenlinc',
        biib_name: 'biogenlinc - simulador lis',
        biib_region: 'latam',
        biib_language: 'es',
        biib_country: 'mx',
        biib_env: 'prod',
        spaWebsite: true,
       } );
      window.dataLayer.push({
        event: 'virtualPageview',
        virtualPageURL: '/lis/simulador/page4',
        virtualPageTitle: 'Lis App | Simulator - Page 4',
        spaWebsite: true,
        contentType: "medical education",
        format: "application",
        contentID:"undefined",
        therapyArea:"sma",
        is_promotional:'non-promotional',
        is_scientific_content: 'No',
        keywords: "",
        product: 'no product',
        target_audience: 'hcp',
        visitorLoginState: 'anonymous',
        pageCategoryLevel1:"lys|application",
        biogenEmail:"FALSE",
        videoLive:"FALSE"
          
        })
      }

      if( indexAudios === 4 )
    {
      window.dataLayer.push({
        event: 'initialization',
        biib_asset_code: 'other|mx|biogenlinc',
        biib_name: 'biogenlinc - simulador lis',
        biib_region: 'latam',
        biib_language: 'es',
        biib_country: 'mx',
        biib_env: 'prod',
        spaWebsite: true,
       } );
      window.dataLayer.push({
        event: 'virtualPageview',
        virtualPageURL: '/lis/simulador/page5',
        virtualPageTitle: 'Lis App | Simulator - Page 5',
        spaWebsite: true,
        contentType: "medical education",
        format: "application",
        contentID:"undefined",
        therapyArea:"sma",
        is_promotional:'non-promotional',
        is_scientific_content: 'No',
        keywords: "",
        product: 'no product',
        target_audience: 'hcp',
        visitorLoginState: 'anonymous',
        pageCategoryLevel1:"lys|application",
        biogenEmail:"FALSE",
        videoLive:"FALSE"
        })
      }

  }, [indexAudios] )
  
  const video = document.getElementById("video")
  // eslint-disable-next-line
  useEffect(() => {
    const video = document.getElementById("video")
    if (indexAudios === 0) {
      video.play()
      video.currentTime = 0
      const sssss = { prop: 10 }
      gsap.to(sssss, {
        duration: 3,
        prop: 200,
        onComplete: function () {
          video.currentTime = 3.1
          video.pause()
        }
      })
    }
    if (indexAudios === 1) {
      video.currentTime = 4.1
      video.play()
      const obj = { prop: 10 }
      gsap.to(obj, {
        duration: 1.11,
        prop: 200,
        onComplete: function () {
          video.play()          
          video.currentTime = 5.11
          video.pause()
        }
      })
    }
    if (indexAudios === 2) {
      video.play()
      const objed = { prop: 10 }
      gsap.to(objed, {
        duration: 1.05,
        prop: 200,
        onComplete: function () {
          video.play()
          video.currentTime = 6.09
          video.pause()
        }
      })
    }
    // eslint-disable-next-line
  }, [indexAudios])

  //
  const playEndVideo = () => {
    video.classList.add("z-50")
    video.play()
    const obj2 = { prop: 10 }
    gsap.to(obj2, {
      duration: 10,
      prop: 200,
      onComplete: function () {
        video.pause()
        history.replace(Rutas.FINALA)
      }
    })
  }

  return (
    <>
      <div className=" h-screen w-screen relative   degrade_blue3   landscape:flex">
        <SliderExp />
          <IntefazExp
            handleFullScreen={handleFullScreen}
            setNextButtonVisible={setNextButtonVisible}
            nextButtonVisible={nextButtonVisible}
            isVideoRunning={!isAudioFinish}
            isTextNextVisible={indexAudios === 3}
          />
        {indexAudios === 0 && (
          <PasoUno
            handleFullScreen={handleFullScreen}
            setNextButtonVisible={setNextButtonVisible}
          />
        )}
        {indexAudios === 1 && (
          <PasoDos
            handleFullScreen={handleFullScreen}
            setNextButtonVisible={setNextButtonVisible}
          />
        )}
        {indexAudios === 2 && (
          <PasoTres
            handleFullScreen={handleFullScreen}
            setNextButtonVisible={setNextButtonVisible}
          />
        )}
        {indexAudios === 3 && (
          <PasoCuatro
            handleFullScreen={handleFullScreen}
            setNextButtonVisible={setNextButtonVisible}
          />
        )}
        {indexAudios === 4 && (
          <PasoCinco
            handleFullScreen={handleFullScreen}
            setNextButtonVisible={setNextButtonVisible}
            finalFunction={playEndVideo}
          />
        )}
        <div className="absolute  object-fill h-screen w-screen  z-20">
          <div
            style={{
              top: Config.isMovile() === "iPhone" ? "57.4%" : "57.4%",
              left: "66.9%"
            }}
            id="meta"
            className=" relative  h-12 w-12 z-50"></div>
        </div>

        <video
          muted
          id="video"
          playsInline
          poster={Img.pasounoexp}
          rel="preload"
          preload="true"
          controls={false}
          className="z-0 absolute  object-fill h-screen w-screen  ">
          <source src={Video.video_bebe_complete} type="video/mp4" />
          Tu navegador no soporta esto intenta Google Chrome
        </video>
      </div>
      <AlertSwitchToHorizontal />
    </>
  )
}

const PasoUno = ({ setNextButtonVisible }) => {
  setNextButtonVisible(true)
  return <></>
}

const PasoDos = ({ setNextButtonVisible, handleFullScreen }) => {
  const [targetOk, setTargetOk] = useState(false)
  const [isBlocked, setisBlocked] = useState(false)

  const { isAudioFinish, changeInteractionState, isFullScreen } = useContext(
    generalContext
  )

  useEffect(() => {
    if (!isAudioFinish) {
      setisBlocked(false)
      setTargetOk(false)
    }
    if (isBlocked) {
      setNextButtonVisible(true)
    }
  }, [isAudioFinish, isBlocked])

  const onFinishInteraction = () => {
    changeInteractionState(true)
  }
  const margenDeErrorHorizontal = 12
  const margenDeErrorVertical = 20
  const isOverTarget = (end, y) => {
    if (
      Math.abs(document.getElementById("meta").offsetLeft - end) <=
      margenDeErrorHorizontal
    ) {
      if (
        Math.abs(y - document.getElementById("meta").offsetTop) <= margenDeErrorVertical
      ) {
        setisBlocked(true)
        onFinishInteraction()
      }
    }
  }

  const isLookTarget = (clientX, clientY) => {
    if (
      Math.abs(clientX - document.getElementById("meta").offsetLeft) <=
      margenDeErrorHorizontal
    ) {
      if (
        Math.abs(clientY - document.getElementById("meta").offsetTop) <=
        margenDeErrorVertical
      ) {
        setTargetOk(true)
        setisBlocked(true)
      } else setTargetOk(false)
      setisBlocked(false)
    } else setTargetOk(false)
    setisBlocked(false)
  }

  useEffect(() => {
    const c2 = document.getElementById("c2")
    const c3 = document.getElementById("c3")
    // eslint-disable-next-line
    var tl = new TimelineMax({ repeat: -1 })
      .to(c2, 0.8, { opacity: 1 })
      .to(c3, 0.8, { opacity: 1 })
      .to(c3, 0.8, { opacity: 0.1 })
      .to(c2, 0.8, { opacity: 0.1 })
  })

  return (
    <>
      {isAudioFinish && (
        <div className="animate-fadein  absolute inset-0  object-fill  z-30 flex justify-center ">
          {/* Target */}
          <Draggable
            handle=".handle"
            defaultPosition={{ x: -50, y: 200 }}
            position={null}
            grid={[5, 5]}
            cancel="nodrag"
            scale={1}
            onStart={(e) => {}}
            onDrag={(e) => {
              try {
                console.log({ e })
                isLookTarget(e.changedTouches[0].pageX, e.changedTouches[0].pageY)
              } catch (error) {
                console.log("Error", error)
              }
            }}
            onStop={(e) => {
              try {
                isOverTarget(e.changedTouches[0].pageX, e.changedTouches[0].pageY)
              } catch (error) {
                console.log("Error", error)
              }
            }}>
            <div className={` h-2 w-2   flex justify-center items-center`}>
              {targetOk ? (
                <img
                  id="c1"
                  onClick={() => {
                    if (!isFullScreen) {
                      console.log(isFullScreen)
                      handleFullScreen.enter()
                    }
                  }}
                  className={`absolute w-5 h-5 z-10  ${
                    isBlocked ? "" : "handle"
                  }  img_target`}
                  src={Img.ellipse1ok}
                  alt="Ok!"
                />
              ) : (
                <img
                  id="c1"
                  className={`absolute w-5 h-5 z-10 ${
                    isBlocked ? "" : "handle"
                  }  img_target`}
                  src={Img.ellipse1}
                  alt="Arrastre! "
                />
              )}
              <img
                id="c2"
                className="absolute w-20 h-20 nodrag   img_target opacity-5  "
                src={Img.ellipse2}
                alt=""
              />
              <img
                id="c3"
                className="absolute w-14 h-14 nodrag  img_target opacity-5 "
                src={Img.ellipse3}
                alt=""
              />
            </div>
          </Draggable>
        </div>
      )}
      {isAudioFinish && (
        <div className="animate-fadein fixed z-10 bottom-0 left-0 mb-3 ">
          <div className="w-full h-full flex justify-start items-end">
            <div className="bg-gray-500 text-xl bg-opacity-75 mx-10  px-10 py-4  rounded-lg">
              <p className=" text-white">LOCALICE EL ESPACIO INTERVERTEBRAL DE L3 Y L4</p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const PasoTres = ({ setNextButtonVisible }) => {
  const { isAudioFinish, changeInteractionState } = useContext(
    generalContext
  )
  const [isBlocked, setisBlocked] = useState(false)
  useEffect(() => {
    if (!isAudioFinish) {
      setisBlocked(false)
    }
    if (isBlocked) {
      setNextButtonVisible(true)
    }
  }, [isAudioFinish, isBlocked])
  const onFinishInteraction = () => {
    console.log("object")
    changeInteractionState(true)
  }
  const isOverTarget = (end, ye) => {
    const x = Math.abs(document.getElementById("meta").offsetLeft - end)
    const y = Math.abs(ye - document.getElementById("meta").offsetTop)
    console.log({ x, y })
    if ((x < 220) & (x > 188)) {
      if ((y < 110) & (y > 76)) {
        setisBlocked(true)
        onFinishInteraction()
      }
    }
  }

  return (
    <>
      {isAudioFinish && (
        <div className="animate-fadein absolute inset-0 overflow-hidden  object-fill  z-30 flex justify-center 	 ">
          <div className="absolute inset-0   w-screen   max-h-screen ">
            <div
              id="meta"
              style={{
                top: "45.7%",
                left: "62.3%"
              }}
              className="  relative  item h-20 w-20 ">
              <TargetAnimation />
            </div>
          </div>
          <div className=" w-full h-full flex ">
            <Draggable
              handle=".handle"
              defaultPosition={{ x: 30, y: 70 }}
              position={null}
              grid={[2, 2]}
              scale={1}
              cancel=".nodragable"
              onStart={(e) => {}}
              onDrag={(e) => {
                console.log(
                  Math.abs(
                    document.getElementById("meta").offsetLeft -
                      e.changedTouches[0].pageX
                  ),
                  Math.abs(
                    e.changedTouches[0].pageY-
                      document.getElementById("meta").offsetTop
                  )
                )
              }}
              onStop={(e) => {
                isOverTarget(e.changedTouches[0].pageX, e.changedTouches[0].pageY)
              }}>
              <div className="  absolute grid grid-cols-8">
                <div className="  absolute col-start-5 grid grid-cols-1 gap-4">
                  <div className="row-start-4 ">
                    <div id="point" className="absolute h-4 w-4   "></div>

                    <div id="target" className={`h-32 w-60 relative `}>
                      <div
                        style={{
                          top: "38%",
                          right: "22.5%"
                        }}
                        className={` position absolute    ${
                          !isBlocked ? "handle" : ""
                        }    h-8 w-8`}></div>
                      <img className=" nodragable w-60" src={Img.aguja} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </Draggable>
          </div>
        </div>
      )}
      {isAudioFinish && (
        <div className="animate-fadein fixed z-10 bottom-0 left-0 mb-3">
          <div className="w-full h-full flex justify-start items-end">
            <div
              className={
                Config.isMovile() === "iPhone"
                  ? "bg-gray-500 text-xl bg-opacity-75 z-0 mx-10  px-10 py-4 max-w-xl rounded-lg max-w-60"
                  : "bg-gray-500 text-xl bg-opacity-75 z-0 mx-10  px-10 py-4 max-w-xl rounded-lg max-w-60"
              }>
              <p className="uppercase text-base text-white text-center ">
                Arrastre la aguja con el bisel hacia arriba al punto indicado
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const PasoCuatro = ({ setNextButtonVisible, handleFullScreen }) => {
  const { isAudioFinish, changeInteractionState, isFullScreen } = useContext(
    generalContext
  )

  setNextButtonVisible(true)
  useEffect(() => {
    if (isAudioFinish) {
      console.log("Finish")
      onFinishInteraction(true)
    }
    // eslint-disable-next-line
  }, [isAudioFinish])

  const onFinishInteraction = () => {
    changeInteractionState(true)
  }
  return (
    <div
      className="absolute h-screen w-screen bg-white z-30"
    >
      <div className="absolute degrade_experience_paso3 h-screen  w-screen flex">
        <div className="h-full w-2/6 pl-10  flex justify-center items-center">
          <img alt="paso 5" src={Img.pasocuatroexp} />
        </div>
        <div className="h-full 2xl:text-sm 3xl:text-base 4xl:text-lg w-3/6 px-10 flex flex-col justify-center items-center ">
          <p className="text-center">
            Una vez que fluya el líquido se tendrá que recolectar en un tubo 5 mL.
          </p>
          <p className="text-center"> Esto ocurrirá en aproximadamente 5 minutos.</p>
          <p className="text-center">
            Verifique que el líquido sea claro y sin trazas de sangre.
          </p>
        </div>
      </div>
    </div>
  )
}
const PasoCinco = (props) => {
  const [botellaAnim, setbotellaAnim] = useState(false)
  const [botClick, setbotClick] = useState(true)
  const { isAudioFinish, isFullScreen } = useContext(generalContext)

  const end = () => {
    props.finalFunction()
  }

  useEffect(() => {
    if (isAudioFinish) {
      setbotellaAnim(false)
      setbotClick(true)
    }
  }, [isAudioFinish])

  useEffect(() => {
    const medicina = document.getElementById("medicina")

    // eslint-disable-next-line
    const tl = new TimelineMax({ repeat: -1 })
      .to(medicina, 0.5, { opacity: 1 })
      .to(medicina, 0.5, { opacity: 0.7 })
  })

  const onClickBotella = () => {
    const medicina = document.getElementById("medicina")
    medicina.classList.add("hidden")
    setbotellaAnim(true)
  }
  return (
    <div
      >
      <div className="z-30  animate-fadein  absolute top-0 w-screen  h-screen">
        <div className="w-full h-full flex items-center ">
          <div className="absolute top-0 w-full h-full flex justify-center  ">
            <div className="item grid grid-cols-6 ">
              <div className="col-start-5 grid grid-cols-1 gap-0 -ml-3">
                <div className="row-start-3">
                  <img src={Img.aguja_target} className="h-28 object-contain" alt="" />
                </div>
              </div>
            </div>
          </div>
          {isAudioFinish && (
            <div>
              {botClick && (
                <div onClick={onClickBotella} className=" ml-10 ">
                  <div className="handlefras ">
                    <img
                      id="medicina"
                      className="w-12 opacity-70 object-contain"
                      src={Img.frasco11}
                      alt=""
                    />
                  </div>
                </div>
              )}
              {botellaAnim && (
                <div className="ml-10 ">
                  <div className="handlefras w-12">
                    <BotellaAnimation finalFunction={end} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {isAudioFinish && (
        <div className="animate-fadein absolute bottom-0 mb-5 z-10">
          <div className="w-full h-full flex justify-start items-end">
            <div
              className={
                Config.isMovile() === "iPhone"
                  ? "bg-gray-500 text-xl bg-opacity-75 mx-10 -mb-6 px-10 py-4 max-w-xl rounded-lg"
                  : "bg-gray-500 text-xl bg-opacity-75 mx-10 mb-6 px-10 py-4 max-w-xl rounded-lg"
              }>
              <p className=" text-white text-center uppercase">
                PARA tomar los 5ml. de Nusinersen pulse el vial. La administración deberá
                ser durante 1 a 3 minutos.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Experiencia
