import React from 'react';
import { Img } from '../../types';

const AlertSwitchToHorizontal = () => {
 return (
   <div className="z-mas fixed inset-0 degrade_blue   landscape:hidden flex justify-center items-center">
        <div className="w-full h-full degrade_blue2 ">
          <div className="w-full h-full  flex flex-col justify-center items-center ">
            <div className="landscape:flex w-10/12 h-80 text-greencorp text-lg Gilroy-Regular	  rounded-3xl flex-col justify-between p-5 items-right bg-white">
              <div className="h-2/5 ">
                <p className="text-left ">
                  Recuerde utilizar su teléfono en{" "}
                  <span className="Gilroy-Bold ">modo horizontal </span> en esta sección.
                </p>
              </div>
              <div className="h-1/5 self-center flex justify-center items-center">
                <img src={Img.movilr} alt="gira tu teléfono" />
              </div>
              <div className="h-2/5 self-center text-center flex justify-center  items-center ">
                <p className="Gilroy-Bold">Por favor gire su teléfono para continuar</p>
              </div>
            </div>
          </div>
        </div>
      </div>
 );
}
 
export default AlertSwitchToHorizontal;