import React from "react";
import { Img } from "../types";
const Unauthorized = () => {
  return (
    <div className="hidden degrade_blue2 no:flex justify-center items-center  fixed inset-0 w-full mx-auto  ">
      <img
        src={Img.logo_un}
        alt="logo "
        className="absolute top-0 mx-auto mt-8 smallheight:mt-5 6xl:mt-10 w-40 smallheight:w-40  6xl:w-56 7xl:w-56"
      />
      <div className="h-full w-10/12 flex justify-center items-center">
        <div className="w-3/6   flex flex-col justify-center ">
          <div className="">
            <h1 className=" 5xl:text-4xl 5xl:text-4xl 6xl:text-5xl 7xl:text-6xl leading-4375 text-bluecorp text-three Gilroy-Bold	">
              Simulador de <br className="5xl:hidden 6xl:block " />
              punción lumbar
            </h1>
            <p className="text-blackcorp text-xl leading-7	mt-16	">
              La aplicación permitirá simular el procedimiento de manera
              realista, <br /> desde el diagnostico hasta el proceso. LIS está
              disponible <br />
              únicamente para dispositivos móviles, escanea el codigo QR con la{" "}
              <br />
              cámará de tu celular ó ingresa tu correo electronico y te
              enviaremos
              <br /> el link de acceso.
            </p>
          </div>
        </div>
        <div className=" h-full w-3/6 flex justify-center items-center">
          <div className=" w-64 6xl:w-72 7xl:w-80 rounded-xl p-5 background_white border-whitecorp	">
            <img
              src={Img.qr}
              className=" 5xl:text-4xl 5xl:text-4xl w-64 6xl:w-72 7xl:w-80 rounded-xl filter_qr  border-bluedarkcorp border-4	"
              alt="qr"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
