import React from "react";
const Slider = ({ ammount, position }) => {
  return (
    <div className="w-full h-9  flex justify-center items-center">
      {ammount.map((d, i) => (
        <div
          key={d.id}
          className={
            i === position
              ? "h-2 w-2 bg-blackcorp rounded-full mr-2 shadow-lg"
              : "h-2 w-2 bg-white rounded-full mr-2 shadow-lg"
          }
        ></div>
      ))}
    </div>
  );
};

export default Slider;
