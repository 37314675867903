import  qr from  "../assets/images/qr.png";
import ellipse1 from  '../assets/images/experiencia/target/ellipse13_1.png';
import ellipse1ok from  "../assets/images/experiencia/target/ellipse13__1__1.png";
import ellipse2 from  "../assets/images/experiencia/target/ellipse12_1.png";
import aguja from  "../assets/images/experiencia/aguja.png";
import ellipse3 from  "../assets/images/experiencia/target/ellipse11_1_1.png";
import play from "../assets/icons/play.svg";
import reload from "../assets/icons/reload.svg";
import pause from "../assets/icons/pause.svg";
import rightrow from "../assets/icons/rightrow.svg";
import stroke from "../assets/icons/Stroke.svg";

import rightcirclerow from "../assets/icons/row_circle_right.svg";
import lis from "../assets/images/lis.png";
import avatar from "../assets/images/avatar.png";
import inicio from "../assets/images/inicial.png";
import logo_un from "../assets/images/lis_logo_unauthorized.png";

// experiencia
import frasco from  "../assets/images/experiencia/frasco.png";
import aguja_target from  "../assets/images/experiencia/aguja.png";
import splashGire from "../assets/images/pasos/splash_gire.png";

import frasco11 from  "../assets/images/experiencia/botella/frasco11.png";
import frasco21 from  "../assets/images/experiencia/botella/frasco21.png";
import frasco31 from  "../assets/images/experiencia/botella/frasco31.png";
import frasco41 from  "../assets/images/experiencia/botella/frasco41.png";

//diagnostico
import union from"../assets/icons/Union.png";
import lisColumna from  "../assets/images/diagnostico/LISColumna.png";
import lisColumnaBlur from  "../assets/images/diagnostico/LIScolumnablur.png";
//
import pasounoexp from  "../assets/images/experiencia/pasouno.jpg";
// import pasodosexp from  "../assets/images/experiencia/pasodos.png";
import pasocuatroexp from  "../assets/images/experiencia/pasotres.png";
//Finish
import escudo_finish from  "../assets/images/pasos/escudo_finish.svg";
import neurodiem from  "../assets/icons/neurodiem1.svg";
import calendar_finish from  "../assets/images/pasos/calendar_finish.svg";
import person_finish from  "../assets/images/pasos/person_finish.svg";
import ilustracion_finish from  "../assets/images/ilustracion_finish.png";
import logouno_finish from  "../assets/images/finish/biog.png";
import movil from  "../assets/icons/movil.png";
import movilr from  "../assets/icons/movilr.png";
import logobiogen from  "../assets/images/logobiogen.png";
import logolis from  "../assets/images/logolis.png";

//Audios
import Mensaje from  "../assets/audios/mensaje.mp3";

// videos
import video_bebe_complete from  "../assets/videos/bebejunto.mp4";
//TYPES
export const NEXT_AUDIO = "NEXT_AUDIO";
export const CHANGE_AUDIO_STATE = "CHANGE_AUDIO_STATE";
export const CHANGE_INTERACTION_STATE = "CHANGE_INTERACTION_STATE";
export const CHANGE_FULLSCREEN_STATE = "CHANGE_FULLSCREEN_STATE";
export const BLOCK_ROUTE = "BLOCK_ROUTE";
export const VERIFY_ROUTE = "VERIFY_ROUTE";
export const SET_ROUTES_BLOCKED_STORE = "SET_ROUTES_BLOCKED_STORE";
export const CHANGE_MUTED_STATE = "CHANGE_MUTED_STATE";
export const RESET_EXPERIENCIA = "RESET_EXPERIENCIA";
export const ANTECEDENTES = 1;
export const ANTES_APLICACION = 2;
export const PREPARACION_PACIENTE = 3;

export const SPLASH_DIAGNOSTICO = 1;
export const SPLASH_GIRE = 2;

export const FINISH_A = 1;
export const FINISH_B = 2;

export const USUARIO = 1;
export const DOCTOR = 2;

export const Rutas = {
  INICIO: "/inicio",
  CHAT: "/chat",
  ANTECEDENTES: "/antecedentes",
  ANTES_DE_APLICACION: "/antes",
  PREPARACION: "/preparacion",
  SPLASH1: "/diagnostico",
  SPLASH2: "/girar",
  EXPERIENCIA: "/simulador",
  FINALA: "/final",
  FINALB: "/despedida",
};

export const initalRoutesBlockedState = {
  INICIO: false,
  CHAT: false,
  ANTECEDENTES: false,
  ANTES_DE_APLICACION: false,
  PREPARACION: false,
  SPLASH1: false,
  SPLASH2: false,
  EXPERIENCIA: false,
  FINALA: false,
  FINALB: false,
};

export const Img = {
  splashGire,
  union,
  rightrow,
  play,
  reload,
  pause,
  avatar,
  lis,
  inicio,
  logo_un,
  qr,
  movil,
  movilr,
  logobiogen,
  logolis,
  //pasos
  neurodiem,
  aguja,
  frasco,
  aguja_target,
  ellipse1,
  ellipse1ok,
  ellipse2,
  ellipse3,
  frasco11,
  frasco21,
  frasco31,
  frasco41,
  pasounoexp,
  stroke,
  // pasodosexp,
  pasocuatroexp,
  // disgnostico
  lisColumna,
  lisColumnaBlur,
  //Finish
  escudo_finish,
  calendar_finish,
  person_finish,
  ilustracion_finish,
  logouno_finish,
  rightcirclerow,
};

export const Video = {
  video_bebe_complete,
};

export const Audio = {
  Mensaje,
};
