//Tipo 1 usuario
//Tipo 2 doctor

export const mensajes = [
  {
    id: 1,
    message:
      "Hola Dr. como agendamos hace unos días, llevaré a Lis para su primer aplicación de Nusinersen.",
    messagebutton: "Responder",
    type: 1,
    user: 1,
    hora: "",
  },
  {
    id: 2,
    message:
      "Sí claro, con gusto realizaremos el procedimiento según lo planeado.",
    type: 2,
    user: 2,
    hora: "",
  },
  {
    id: 3,
    message:
      "Doctor, quisiera preguntarle si ¿Es seguro ponerle el medicamento a través de la columna?",
    messagebutton: "Beneficios de la administracion IT",
    type: 1,
    user: 1,
    hora: "",
  },
  {
    id: 4,
    message:
      "La administración intratecal es una vía fundamental en el  suministro de medicamentos cuyo mecanismo de acción está  dirigido al SNC como en el caso de AME.[1]",
    type: 2,
    user: 2,
    hora: "",
  },
  {
    id: 5,
    message: "¿Y cuáles son los beneficios de hacerlo así?",
    messagebutton: "Hablar sobre dosis terapéutica",
    type: 1,
    user: 1,
    hora: "",
  },
  {
    id: 6,
    message:
      "Permite alcanzar altas concentraciones de Nusinersen  en la neurona motora, cuya supervivencia depende  de las concentraciones de la proteína SMN.[1]",
    messagebutton: "Hablar sobre toxicidad",
    type: 2,
    user: 2,
    hora: "",
  },
  {
    id: 7,
    message:
      "Además minimiza la exposición de Nusinersen fuera  del objetivo terapéutico y disminuye la  toxicidad sistémica.[1]",
    messagebutton: "Hablar sobre adherencia",
    type: 2,
    user: 2,
    hora: "",
  },
  {
    id: 8,
    message:
      "Esto nos permite mantener un apego al tratamiento y un estricto seguimiento médico.",
    messagebutton: "Seguridad de la administración IT",

    type: 2,
    user: 2,
    hora: "",
  },
  {
    id: 9,
    message:
      "De hecho, los eventos adversos son predecibles, y estos disminuyen a mayor  experiencia del profesional de la salud que realiza la  administración.[2]",
    messagebutton: null,
    type: 2,
    user: 2,
    hora: "",
  },
  {
    id: 10,
    message:
      "Dr. estoy un poco preocupada acerca de la situación con COVID-19, ¿Es seguro ir al hospital para realizar el procedimiento?",
    messagebutton: "Seguridad de la administración IT",
    type: 1,
    user: 1,
    hora: "",
  },
  {
    id: 11,
    message:
      "Es totalmente normal estar preocupada, le comento que se toman todas las medidas de seguridad en el hospital.",
    messagebutton: "Seguridad de la administración IT",
    type: 2,
    user: 2,
    hora: "",
  },
  {
    id: 12,
    message:
      "También es importante tener en mente que el inicio del tratamiento es sumamente importante para no deteriorar la salud de Lis.",
    messagebutton: "Seguridad de la administración IT",
    type: 2,
    user: 2,
    hora: "",
  },
  {
    id: 13,
    message:
      "La pérdida de funciones motoras o deterioro pulmonar de Lis es un riesgo que no debemos permitir.",
    messagebutton: null,
    type: 2,
    user: 2,
    hora: "",
  },
  {
    id: 14,
    message: "Gracias Dr. me quedo mucho más tranquila con su explicación.",
    messagebutton: "Terminar conversación",
    type: 1,
    user: 1,
    hora: "",
  },
  {
    id: 15,
    message: "Perfecto, repasemos los antecedentes de Lis.",
    messagebutton: "Abrir antecedentes",

    type: 2,
    user: 2,
    hora: "",
  },
];
