import React, { useEffect, useRef } from "react";

const Waiting = () => {
  const refMessage = useRef();

  useEffect(() => {
    // Posiciona el mensaje a la vista cuando se genera
    refMessage.current.scrollIntoView();
  }, []);
  return (
    <div
      ref={refMessage}
      className="w-full animate-popupmessage mt-2.5 mb-3	flex flex-col justify-end items-end"
    >
      <div className="w-4438 h-2063  bg-celestedeg  text-sm	 shadow-lg rounded-tl-xl rounded-tr-xl rounded-bl-xl flex items-center justify-around">
        <div className="flex">
          <div className="w-563 h-563  bg-white rounded-full animate-pulse"></div>
          <div className="w-563 h-563 ml-2 bg-white rounded-full animate-pulse"></div>
          <div className="w-563 h-563 ml-2 bg-white rounded-full animate-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default Waiting;
