import React, { useContext, useEffect } from "react";
import { Img, Rutas } from "../../../types";
import GeneralContext from "../../../stores/general/general.context";
import { useHistory } from "react-router-dom";

const Inicio = () => {

 

  const { changeFullScreen } = useContext(GeneralContext);

  const history = useHistory();
  console.log("Cargando inicio");
  /* eslint-disable */

  const onEntryFullScreen = () => {
    changeFullScreen(true);
    history.push(Rutas.CHAT);
  };

  useEffect( () => {
    
    
    window.dataLayer.push({
      event: 'initialization',
      biib_asset_code: 'other|mx|biogenlinc',
      biib_name: 'biogenlinc - simulador lis',
      biib_region: 'latam',
      biib_language: 'es',
      biib_country: 'mx',
      biib_env: 'prod',
    });

    window.dataLayer.push({
      event: 'virtualPageview',
      virtualPageURL: '/lis/inicio',
      virtualPageTitle: 'Lis App | Home page',
      spaWebsite: true,
      contentType: "medical education",
      format: "application",
      contentID:"undefined",
      therapyArea:"sma",
      is_promotional:'non-promotional',
      is_scientific_content: 'No',
      keywords: "",
      product: 'no product',
      target_audience: 'hcp',
      visitorLoginState: 'anonymous',
      pageCategoryLevel1:"lys|application",
      biogenEmail:"FALSE",
      videoLive:"FALSE"
    });

  }, [])


  return (
    <div className="bg-white fixed inset-0 h-full">
      <div className="w-full relative animate-fadein background_blue_initial  fixed inset-0 h-full ">
        <div className="absolute inset-0  flex justify-center items-center">
          <img
            src={Img.inicio}
            className="img-webpo background_blue_colum object-fit   "
          />
        </div>
        <div className="h-full w-full background_blue_initial2">
          <div className="w-full h-full Gilroy-Medium absolute z-10 sm:my-5 md:py-5 lg:py-10 xl:py-2 xl:pt-8 2xl:py-3 md:pl-10  lg:pl-10 md:pr-14 lg:pr-20 xl:pr-12 text-bluecorp">
            <h3 className=" md:leading-8 xl:leading-10 md:text-xl	 lg:text-2xl xl:text-twohalf md:mb-3 lg:mb-9 xl:mb-5">
              ¡Bienvenido al simulador de punción lumbar!
            </h3>
            <h4 className="md:text-base  lg:text-base xl:text-lg Gilroy-Regular ">
              Recuerde usar su teléfono para esta experiencia
            </h4>
          </div>
          <div className="absolute z-10 bottom-0 right-0 flex justify-center mr-7 mb-7 items-center ">
            <span className="text-white text-lg	m-3.5">Comenzar</span>
            <button
              onClick={onEntryFullScreen}
              className=" md:w-12 animate-pulse md:h-12 xl:w-75 xl:h-75 shadow-3xl bg-whitecorp sm:rounded-lg md:rounded-lg xl:rounded-3xl  "
            >
              <img
                className="m-auto  	  sm:w-2 lg:w-auto "
                alt="flecha hacia la derecha"
                src={Img.rightrow}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inicio;
