import React, { useEffect, useRef } from "react";
import moment from "moment";
const MessageLeft = ({ data }) => {
  const { message } = data;
  const refMessage = useRef();

  useEffect(() => {
    // Posiciona el mensaje a la vista cuando se genera
    refMessage.current.scrollIntoView({
      behavior: "smooth",
    });
  }, []);
  console.log(data.id, ": Izquierdo");
  return (
    <div ref={refMessage} className="w-full mb-3 animate-popupmessage mt-2.5 ">
      <div className="w-10/12 h-5/6 bg-white p-5 sm:text-xs text-sm	 shadow-lg rounded-tl-xl rounded-tr-xl rounded-br-xl	">
        {message}
      </div>
      <div className="text-bluecorp font-light h-1/6 w-full text-xs mt-2.5	">
        <b> Laura </b> <span> {moment().format("LT")} </span>
      </div>
    </div>
  );
};

export default React.memo(MessageLeft);
