import React, { useEffect, useRef, useState, useContext } from "react";
import GeneralContext from "../../stores/general/general.context";
import { useHistory } from "react-router-dom";
import { Img, Rutas } from "../../types";
import Config from "../../config";

const IntefazExp = ({ isTextNextVisible,setNextButtonVisible,nextButtonVisible,handleFullScreen }) => {
  const refAudio = useRef();
  let history = useHistory();

  const [audioMuted, setAudioMuted] = useState(false);
  const [audioPaused, setAudioPaused] = useState(false);
  // console.log("Renderizando interfaz");
  const {
    indexAudios,
    currentAudio,
    currentSubtitle,
    totalAudios,
    nextAudio,
    changeStateAudio,
    changeMutedstate,
    isFullScreen,
    isMuted,
    isInteractionFinish,
    changeInteractionState,
  } = useContext(GeneralContext);

  const handlerMute = () => {
    if (isMuted) {
      changeMutedstate(false);
    } else {
      changeMutedstate(true);
    }
  };

  useEffect(() => {
    if (isMuted) {
      refAudio.current.muted  = true;
    } else {
      refAudio.current.muted = false;
      // refAudio.current.muted  = true
    }
  }, [isMuted])

  const handlerPlayPause = () => {
    if (refAudio.current.paused) {
      refAudio.current.play();
    } else {
      refAudio.current.pause();
    }
    setAudioPaused(!refAudio.current.paused);
  };
  const handlerReload = () => {
    setNextButtonVisible(false);
    changeStateAudio(false);
    if (indexAudios !== 0) {
      changeInteractionState(false);
    }
    refAudio.current.pause();
    refAudio.current.currentTime = 0;
    refAudio.current.play();
  };
  // eslint-disable-next-line
  useEffect(() => {
    handleEnd();
    setAudioPaused(refAudio.current.paused);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refAudio.current.src = currentAudio;
    setNextButtonVisible(false);
  }, [currentAudio]);

  const handleEnd = () => {
    refAudio.current.addEventListener("ended", function (e) {
      changeStateAudio(true);
      setAudioPaused(true);
      setAudioPaused(true);
      // console.log("Audio terminó", { e });
    });
  };
  const onClickNext = () => {
    changeStateAudio(false);
    // changeInteractionState(false);
    if (totalAudios === indexAudios + 1) {
      history.push(Rutas.FINALA);
    } else {
      nextAudio();
    }
  };
  //
  const [landscape, setLandscape] = useState(false);

  useEffect(() => {
    risezed();
  });

  useEffect(() => {
    if (landscape) {
      refAudio.current.play();
    } else {
      refAudio.current.pause();
    }
  }, [landscape]);

  window.addEventListener("resize", () => risezed());

  const risezed = () => {
    if (window.innerWidth > window.innerHeight) {
      setLandscape(true);
    } else {
      setLandscape(false);
    }
  };
  useEffect(() => {
    const location = history.location.pathname;
    if (location === Rutas.SPLASH1 || location === Rutas.SPLASH2) {
    } else {
    }
  }, [history.location.pathname]);
  //

  return (
    <>
    <div className="fixed top-0 z-0 h-screen w-screen background-first-frame-video ">

    </div>
      <video
        playsInline
        className={
          isMuted
            ? Config.isMovile() === "iPhone "
              ? "mb-0 fixed bottom-0 left-0 w-full z-20"
              : "mb-0 fixed bottom-0 left-0 w-full z-20"
            : Config.isMovile() === "iPhone"
            ? "mb-0 fixed bottom-0 left-0 w-full opacity-0 z-20"
            : "mb-0 fixed bottom-0 left-0 w-full opacity-0 z-20"
        }
        preload="auto"
        ref={refAudio}
        autoPlay
        controls={false}
      >
        <source 
        src={currentAudio} type="audio/mpeg" />

        <track
          kind="subtitles"
          label="Subtítulos en español"
          src={currentSubtitle}
          default
        />
      </video>
      {/* <audio preload="auto" ref={refAudio} autoPlay>
        <source src={currentAudio} type="audio/mpeg" />
      </audio> */}
      {
        <div className="z-50 fixed top-0 right-0 pr-4 pt-5 ">
          <div
            onClick={handlerMute}
            className="w-48 text-sm bg-white py-3 Gilroy-Medium shadow-lg rounded-lg text-center"
          >
            {isMuted
              ? "Activar sonido"
              : isTextNextVisible
              ? "Silenciar"
              : "Silenciar y activar subtitulos"}
          </div>
        </div>
      }

      <div className="z-50 fixed mt-14 right-0 pr-4 pt-5">
        <div
          onClick={handlerPlayPause}
          className="w-10 h-10 text-sm shadow-custom bg-white flex justify-center items-center Gilroy-Medium  rounded-lg text-center"
        >
          {audioPaused ? (
            <img alt="play" src={Img.pause} />
          ) : (
            <img alt="play" src={Img.play} />
          )}
        </div>
        <div
          onClick={handlerReload}
          className="w-10 h-10 mt-2 shadow-custom text-sm bg-white  flex justify-center items-center Gilroy-Medium  rounded-lg text-center"
        >
          <img alt="reload" src={Img.reload} />
        </div>
      </div>
      {nextButtonVisible && isInteractionFinish && (
        <div
          style={{
            zIndex:100
          }}
          className="fixed animate-fadein z-50 bottom-0 right-0 flex justify-center pr-4  items-center "
        
        >
          {isTextNextVisible && (
            <span className="mr-5 text-white mb-5  Inter text-lg">Siguiente</span>
          )}

          <button
            onClick={onClickNext}
            className=" animate-pulse md:w-12 md:h-12 xl:w-12 xl:h-12 mb-5 bg-white sm:rounded-lg md:rounded-lg xl:rounded-xl  "
            to={Rutas.FINALB}
          >
            <img
              className="m-auto 	"
              alt="reload"
              src={Img.rightrow}
            />
          </button>
        </div>
      )}
    </>
  );
};

export default IntefazExp;
