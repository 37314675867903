import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Img, Rutas } from "../../types";

const ButtonFooter = ({ title, onClick }) => {
  const history = useHistory();
  const [elevate, setelevate] = useState(false);
  useEffect(() => {
    const location = history.location.pathname;
    if (
      location === Rutas.ANTECEDENTES ||
      location === Rutas.PREPARACION ||
      location === Rutas.ANTES_DE_APLICACION
    ) {
      setelevate(true);
    } else {
      setelevate(false);
    }
  }, [history.location.pathname]);

  const [clase, setClase] = useState("");

  useEffect(() => {
    if (title && title.length > 25) {
      setClase("-ml-6");
    } else {
      setClase("");
    }
  }, [title]);

  return (
    <div
      className={`animate-fadein md:h-16 h-16 lg:h-20 ${
        elevate ? "" : ""
      }  absolute bottom-0 sm:px-8 xl:px-8 left-0 right-0 flex`}
    >
      <button
        onClick={onClick}
        className="bg-whitecorp  Inter  relative text-bluecorp sm:text-xs lg:text-base  shadow-lg font-normal rounded-xl sm:h-12 md:h-10 lg:h-14 xl:h-14	 w-full"
      >
        <span className={clase}>{title}</span>
        <div className="absolute right-0    top-0 bottom-0 flex justify-center items-center">
          <img
            src={Img.stroke}
            className=" mr-5 md:w-3 xl:mr-4   sm:w-3 sm:h-3 md:h-3  xl:h-4 xl:w-4  widthcustomstroke__buttonfooter  "
            alt="flecha"
          />
        </div>
      </button>
    </div>
  );
};

export default ButtonFooter;
