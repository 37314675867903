import React, { useCallback, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Chat from "./Chat";
import Pasos from "./Pasos";
import Video from "./subviews/Bienvenida/Video";
import Inicio from "./subviews/Bienvenida/Inicio";
import Splash from "./Splash";
import Finish from "./FInish";
import Config from "../config";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import {
  ANTES_APLICACION,
  PREPARACION_PACIENTE,
  ANTECEDENTES,
  SPLASH_DIAGNOSTICO,
  SPLASH_GIRE,
  FINISH_A,
  FINISH_B,
  Rutas,
} from "../types";
import Experiencia from "./Experiencia";
import Warning from "../components/general/Warning";
import GeneralContext from "../stores/general/general.context";





const Home = () => {
  const handle = useFullScreenHandle();
  const {
   changeFullScreen
  } = useContext(GeneralContext);
  const [movile, setmovile] = useState(true);
  // eslint-disable-next-line
  useEffect(() => {
    if (Config.isMovile() === "iPhone") {
      setmovile(false);
    }
  });
  // eslint-disable-next-line
  const reportChange = useCallback( ( state, handle ) => {
    // eslint-disable-next-line
     changeFullScreen(state)
     // eslint-disable-next-line
  }, [handle]);

  return (
    <>
      {movile && (
        <button
          className="z-50 absolute w-screen h-screen"
          onClick={handle.enter}
        ></button>
      )}
      <FullScreen handle={handle} onChange={reportChange}>
        <div className="no:hidden  ">
          <Router>
              <Switch>
                <Route exact path="/">
                  <Warning />
                  <Video />
                </Route>
                <Route exact path={Rutas.INICIO}>
                  <Warning />
                  <Inicio />
                </Route>
                <Route exact path={Rutas.CHAT}>
                  <Warning />
                  <Chat />
                </Route>
                <Route exact path={Rutas.ANTECEDENTES}>
                  <Warning />
                  <Pasos type={ANTECEDENTES} />
                </Route>
                <Route exact path={Rutas.ANTES_DE_APLICACION}>
                  <Warning />
                  <Pasos type={ANTES_APLICACION} />
                </Route>
                <Route exact path={Rutas.PREPARACION}>
                  <Warning />
                  <Pasos type={PREPARACION_PACIENTE} />
                </Route>
                <Route path={Rutas.SPLASH1}>
                  <Warning />
                  <Splash type={SPLASH_DIAGNOSTICO} />
                </Route>
                <Route path={Rutas.SPLASH2}>
                  <Splash handleFullScreen={handle} type={SPLASH_GIRE} />
                </Route>
                <Route exact path={Rutas.EXPERIENCIA}>
                  <Experiencia handleFullScreen={handle} />
                </Route>
                <Route exact path={Rutas.FINALA}>
                  <Finish type={FINISH_A} />
                </Route>
                <Route exact path={Rutas.FINALB}>
                  <Finish type={FINISH_B} />
                </Route>
              </Switch>
          </Router>
        </div>
    
      </FullScreen>
    </>
  );
};

export default Home;
