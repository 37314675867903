import audioFinal from "../assets/audios/AudioLIS2.0-ExcelentePractica.mp3"
import subtitulos1 from "../assets/vozenoff/subtitulos/audio1.vtt";
import subtitulos2 from "../assets/vozenoff/subtitulos/audio2.vtt";
import subtitulos3 from "../assets/vozenoff/subtitulos/audio3.vtt";
import subtitulos4 from "../assets/vozenoff/subtitulos/audio4.vtt";
import subtitulos5 from "../assets/vozenoff/subtitulos/audio5.vtt";
import audiouno from "../assets/vozenoff/GUIÓN SIMULADOR PUNCIÓN_1 (1).mp3";
import audiodos from "../assets/vozenoff/GUIÓN SIMULADOR PUNCIÓN_2.mp3";
import audiotres from "../assets/vozenoff/GUIÓN SIMULADOR PUNCIÓN_3.mp3";
import audiocuatro from "../assets/vozenoff/GUIÓN SIMULADOR PUNCIÓN_4.mp3";
import audiocinco from "../assets/vozenoff/GUIÓN SIMULADOR PUNCIÓN_5.mp3";



export const subtitulos = [
    subtitulos1,
    subtitulos2,
    subtitulos3,
    subtitulos4,
    subtitulos5,
];
export const audios = [audiouno, audiodos, audiotres, audiocuatro, audiocinco, audioFinal];