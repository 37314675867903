import React, { useContext } from "react";
import GeneralContext from "../../stores/general/general.context";

const SliderExp = () => {
  const { indexAudios, audios } = useContext(GeneralContext);
  return (
    <div className="fixed z-40  left-0 top-0 ml-4 mt-5   w-48 h-10">
      <div className="w-full h-full  relative ">
        <div className=" absolute inset-0 flex  items-center justify-center">
          <div 
            className={(indexAudios ===3) ? 'w-full h-1 bg-bluesoftcorp':'w-full h-1 bg-white'

            }></div>
        </div>
        <div className=" absolute inset-0 flex  items-center justify-between">
          {audios.map((_, i) => (
            <div
              key={i}
              className={
                i <= indexAudios
                  ? "w-4 h-4 animate-fade	 rounded-full bg-bluesoftcorp"
                  : "w-4 h-4 rounded-full bg-white border-green-300 border"
              }
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SliderExp;
