import React, { useEffect, useState } from "react";
import { Img, Rutas } from "../../types";
import { useHistory } from "react-router-dom";

const Warning = () => {
  const history = useHistory();

  const [isExperiencia, setIsExperiencia] = useState(false);
  useEffect(() => {
    if (history.location.pathname === Rutas.EXPERIENCIA) {
      setIsExperiencia(true);
    } else {
      setIsExperiencia(false);
    }
  }, [history.location.pathname]);

  return (
    <div className="fixed inset-0 z-50  bg-white hidden landscape:flex">
      <div className="w-full h-full degrade_blue2">
        <div className="w-full h-full bg-blackwarning flex flex-col justify-center items-center">
          <div
            className={`${
              isExperiencia
                ? "w-10/12 h-80"
                : " hidden landscape:flex landscape:w-6/12 h-60"
            } text-greencorp text-lg Gilroy-Light	  rounded-3xl flex-col justify-between p-5 items-right bg-white`}
          >
            <div className="h-1/5 ">
              <p className="text-left ">
                Recuerde utilizar {isExperiencia ? <br /> : null}
                su teléfono en{" "}
                <span className="Gilroy-Bold ">modo vertical </span>{" "}
                {isExperiencia ? <br /> : null} en esta sección.
              </p>
            </div>
            <div className="h-2/5 self-center flex justify-center items-center">
              <img src={Img.movil} alt="gira el movil" />
            </div>
            <div className="h-1/5 self-center text-center flex justify-center  items-center ">
              <p className="Gilroy-Medium">
                Por favor gire su teléfono para continuar
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Warning);
