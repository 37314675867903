import React from "react";
import SplashContinuar from "../components/splash/SplashContinuar";
import SplashDiagnostico from "../components/splash/SplashDiagnostico";
import { SPLASH_DIAGNOSTICO, SPLASH_GIRE } from "../types";

const Splash = ({ type,handleFullScreen }) => {
  return (
      <div className="w-full fixed inset-0 h-full bg-white ">
        {type === SPLASH_DIAGNOSTICO && <SplashDiagnostico />}
        {type === SPLASH_GIRE && <SplashContinuar handleFullScreen={handleFullScreen}/>}
      </div>
  );
};

export default Splash;
